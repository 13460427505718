import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { sesion } from './getPermisos'

const RutaProtegida = ({ permisos }) => {
    const location = useLocation();

    // Se valida que el permiso requerido exista en los permisos d la sesión
    // Si se solicita validar la ruta (validarRuta = true), además de verificar que existanh el permiso en la sesión, se valida que la ruta actual sea igual a la del permiso

    return (
        (!permisos) || (permisos && (sesion.find(permiso => permisos.includes(permiso)) ? true : false))
            ? <Outlet /> // Autorizado
            : <Navigate to='/inicio' />
    )
};

export default RutaProtegida