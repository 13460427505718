import React, { Fragment, useState, useEffect } from 'react';
import {NumericFormat} from 'react-number-format';
import {objetoFormularios} from '../../../js/validacion_formularios'
import MySelect from '../../../components/common/select'
import 'react-toastify/dist/ReactToastify.css';
import { Phone } from 'react-feather'
import Toast from '../../common/tool_toast'
import Tools from '../../../js/tools'

const Default = (props) => {
    const [optionEstados, setOptionEstados] = useState([])
    const [selectedEstado, setSelectedEstado] = useState(null)
    const [estatusMostrarMsj, setEstatusMostrarMsj] = useState(false)
    const [jsonForms, setJsonForms] = useState({})
    const [datosEmpresa, setDatosEmpresa] = useState(props.datosEmpresa? props.datosEmpresa: {})
    const [optionsRegimen, setOptionsRegimen] = useState({})
    const [optionsUsoCfdi, setOptionsUsoCfdi] = useState({})
    const [selectedRegimen, setSelectedRegimen] = useState(null)
    const [selectedUsoCfdi, setSelectedUsoCfdi] = useState(null)

    useEffect(() => {
        let isSubscribed = true
        Tools.toolFetch({ 
            ruta: 'rt_catalogos_fiscales',
            method: 'GET',
        }, (response) => {
            if(isSubscribed){
                let _optionsEstados = response.resultado[0].map((v) => ({ label: v.NOMBRE_ESTADO, value: v })),
                    _optionsRegimen = response.resultado[1].map((v) => ({ label: `${v.CLAVE_REGIMEN} - ${v.DESCRIPCION_REGIMEN}`, value: v })),
                    _optionsUsoCfdi = response.resultado[2].map((v) => ({ label: `${v.CLAVE_USO_CFDI} - ${v.DESCRIPCION_USO_CFDI}`, value: v }))

                setOptionEstados(_optionsEstados)
                setOptionsRegimen(_optionsRegimen)
                setOptionsUsoCfdi(_optionsUsoCfdi)
                setSelectedEstado(_optionsEstados.find((v) => v.value.ID_ESTADO === datosEmpresa.ID_ESTADO))
                setSelectedRegimen(_optionsRegimen.find((v) => v.value.CLAVE_REGIMEN === datosEmpresa.REGIMEN_FISCAL))
                setSelectedUsoCfdi(_optionsUsoCfdi.find((v) => v.value.CLAVE_USO_CFDI === datosEmpresa.RECEPTOR_USO_CFDI))
            }
        })
        return () => isSubscribed = false
    }, [])

    const onClickEditar = (formArray, camposIngnore) => {
        objetoFormularios.validarFormulario(formArray, camposIngnore, (response) => {
            objetoFormularios.onClickAgregarDatos(response, (response) => {
                if(response.estatus){
                    if(selectedEstado && selectedRegimen && selectedUsoCfdi){
                        response.jsonForms.formEmpresa['id_empresa'] = props.datosEmpresa.ID_EMPRESA
                        response.jsonForms.formEmpresa['list_estado'] = selectedEstado.value.ID_ESTADO
                        response.jsonForms.formEmpresa['list_regimen_fiscal'] = selectedRegimen.value.CLAVE_REGIMEN
                        response.jsonForms.formEmpresa['list_uso_cfdi'] = selectedUsoCfdi.value.CLAVE_USO_CFDI
                        props.enviarEmpresa(response.jsonForms.formEmpresa)
                        setJsonForms(response.jsonForms)
                    }else{
                        Toast.onClickToastWarn({html:<div>- Debe de seleccionar un elemento de la lista</div>})
                    }
                }else{
                    Toast.onClickToastWarn({html:<div dangerouslySetInnerHTML={{__html: response.msj}}></div>})
                    setJsonForms(response.jsonForms)
                    setEstatusMostrarMsj(true)
                }
            })
        })
    }

    const setSwitch = (key) => {
        if(estatusMostrarMsj){
            switch (key) {
                case 'txt_nombre':
                    if(!jsonForms.jsonVacio.txt_nombre){
                        return <span className="text-danger">(Alias) son requeridos</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_calle':
                    if(!jsonForms.jsonVacio.txt_calle){
                        return <span className="text-danger">(Calle y Número) son requeridos</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_num_int':
                    if(jsonForms.jsonVacio.txt_num_int === false){
                        return <span className="text-danger">(Número Interior) son requeridos</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_num_ext':
                    if(jsonForms.jsonVacio.txt_num_ext === false){
                        return <span className="text-danger">(Número Exterior) son requeridos</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_colonia':
                    if(!jsonForms.jsonVacio.txt_colonia){
                        return <span className="text-danger">(Colonia) es requerida</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_lugar_expedicion':
                    if(!jsonForms.jsonVacio.txt_lugar_expedicion){
                        return <span className="text-danger">(Lugar Expedición) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'list_estado':
                    if(!jsonForms.jsonList.list_estado){
                        return <span className="text-danger">Debe de seleccionar un (Estado)</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_ciudad':
                    if(!jsonForms.jsonVacio.txt_ciudad){
                        return <span className="text-danger">(Ciudad) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_regimen_fiscal':
                    if(!jsonForms.jsonVacio.txt_regimen_fiscal){
                        return <span className="text-danger">(Regimen Fiscal) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'tel_direccion1':
                    if(!jsonForms.jsonVacio.tel_direccion1){
                        return <span className="text-danger">(Teléfono) es requerido</span>
                    }else if(!jsonForms.jsonTel.tel_direccion1){
                        return <span className="text-danger">(Teléfono) incorrecto eje: (833-367-1211)</span>
                    }else{
                        return ''
                    }
                break;
                case 'tel_direccion2':
                    if(jsonForms.jsonVacio.tel_direccion2 === false){
                        return <span className="text-danger">(Teléfono 2) es requerido</span>
                    }else if(jsonForms.jsonTel.tel_direccion2 === false){
                        return <span className="text-danger">(Teléfono 2) incorrecto eje: (833-367-1211)</span>
                    }else{
                        return ''
                    }
                break;
                case 'txt_rs':
                    if(!jsonForms.jsonVacio.txt_rs){
                        return <span className="text-danger">(Razón Social) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_rfc':
                    if(!jsonForms.jsonVacio.txt_rfc){
                        return <span className="text-danger">(R.F.C.) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'correo_empresa':
                    if(!jsonForms.jsonVacio.correo_empresa){
                        return <span className="text-danger">(Correo Electrónico) es requerido</span>
                    }else if(!jsonForms.jsonCorreo.correo_empresa){
                        return <span className="text-danger">(Correo Electrónico) incorrecto eje: (info@ejemplo.com)</span>
                    }else{
                        return ''
                    }
                break;
                case 'txt_uso_cfdi':
                    if(!jsonForms.jsonVacio.txt_uso_cfdi){
                        return <span className="text-danger">(Uso CFDI) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_tipo_cfdi':
                    if(!jsonForms.jsonVacio.txt_tipo_cfdi){
                        return <span className="text-danger">(Tipo CFDI) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_serie':
                    if(!jsonForms.jsonVacio.txt_serie){
                        return <span className="text-danger">(Serie) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_metodo_pago':
                    if(!jsonForms.jsonVacio.txt_metodo_pago){
                        return <span className="text-danger">(Metodo Pago) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_forma_pago':
                    if(!jsonForms.jsonVacio.txt_forma_pago){
                        return <span className="text-danger">(Forma Pago) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_condicion_pago':
                    if(!jsonForms.jsonVacio.txt_condicion_pago){
                        return <span className="text-danger">(Condición Pago) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_com_moneda':
                    if(!jsonForms.jsonVacio.txt_com_moneda){
                        return <span className="text-danger">(Moneda) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_tipo_cambio':
                    if(!jsonForms.jsonVacio.txt_tipo_cambio){
                        return <span className="text-danger">(Tipo Cambio) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_tipo_comprobante':
                    if(!jsonForms.jsonVacio.txt_tipo_comprobante){
                        return <span className="text-danger">(Tipo Comprobante) es requerido</span>
                    }else {
                        return ''
                    }
                break;
                case 'txt_cp':
                    if(!jsonForms.jsonVacio.txt_cp){
                        return <span className="text-danger">(C.P) es requerido</span>
                    }else {
                        return ''
                    }
                break;
            }
        }  
    }

    return (
        <Fragment>
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <h5 style={{display: 'inline'}}>DATOS EMPRESA Y FACTURACIÓN</h5>
                         </div>
                         {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <h5 style={{display: 'inline'}}>DATOS FACTURACIÓN</h5>
                         </div> */}
                    </div>
                    {/* <h6 style={{display: 'inline'}}>(SUCURSAL/ESTACIÓN DE SERVICIO)</h6> */}
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <form className='row' id='formEmpresa'>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Nombre Comercial<span className="text-danger">*</span></label>
                                                    <input id="txt_nombre" name='txt_nombre' className="form-control" type="text" 
                                                    placeholder="Nombre Comercial" defaultValue={datosEmpresa.NOMBRE_COMERCIAL? datosEmpresa.NOMBRE_COMERCIAL: ''} />
                                                    {setSwitch('txt_nombre')}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Correo<span className="text-danger">*</span></label>
                                                    <input id="correo_empresa" name='correo_empresa' className="form-control" type="text" placeholder="Correo" 
                                                    defaultValue={datosEmpresa.CORREO? datosEmpresa.CORREO: ''}/>
                                                    {setSwitch('correo_empresa')}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Calle<span className="text-danger">*</span></label>
                                                    <input id="txt_calle" name='txt_calle' className="form-control" type="text" 
                                                    placeholder="Calle" defaultValue={datosEmpresa.CALLE_NUMERO? datosEmpresa.CALLE_NUMERO: ''} />
                                                    {setSwitch('txt_calle')}
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label">Num. Ext<span className="text-danger">*</span></label>
                                                    <input id="txt_num_ext" name='txt_num_ext' className="form-control" type="text" 
                                                    placeholder="Numero Exterior" defaultValue={datosEmpresa.NUM_EXT? datosEmpresa.NUM_EXT: ''} />
                                                    {setSwitch('txt_num_ext')}
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label">Num. Int<span className="text-danger">*</span></label>
                                                    <input id="txt_num_int" name='txt_num_int' className="form-control" type="text" 
                                                    placeholder="Numero Interior" defaultValue={datosEmpresa.NUM_INT? datosEmpresa.NUM_INT: ''} />
                                                    {setSwitch('txt_num_int')}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="form-group">
                                                    <label className="form-label">Colonia<span className="text-danger">*</span></label>
                                                    <input id="txt_colonia" name='txt_colonia' className="form-control" type="text" placeholder="Colonia" 
                                                    defaultValue={datosEmpresa.COLONIA? datosEmpresa.COLONIA: ''}/>
                                                    {setSwitch('txt_colonia')}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-5 mb-3">
                                                <label className="form-label">Lugar Expedición (CP)<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input id="txt_lugar_expedicion" name='txt_lugar_expedicion' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.COM_LUGAR_EXPEDICION? datosEmpresa.COM_LUGAR_EXPEDICION: ''}/>
                                                </div>
                                                {setSwitch('txt_lugar_expedicion')}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Estado<span className="text-danger">*</span></label>
                                                    <MySelect
                                                        id='list_estado'
                                                        name='list_estado'
                                                        onChange={(v) => setSelectedEstado(v)}
                                                        defaultValue={optionEstados.length >0? selectedEstado: null}
                                                        value={optionEstados.length >0? selectedEstado : null}
                                                        options={optionEstados} />
                                                        {setSwitch('list_estado')} 
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Ciudad Alcaldia<span className="text-danger">*</span></label>
                                                    <input id="txt_ciudad" name='txt_ciudad' className="form-control" type="text" placeholder="Ciudad Alcaldia" 
                                                    defaultValue={datosEmpresa.CIUDAD_ALCALDIA? datosEmpresa.CIUDAD_ALCALDIA: ''}/>
                                                    {setSwitch('txt_ciudad')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className='row'>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">R.F.C<span className="text-danger">*</span></label>
                                                    <input id="txt_rfc" name='txt_rfc' className="form-control" type="text" placeholder="R.F.C" 
                                                    defaultValue={datosEmpresa.RFC? datosEmpresa.RFC: ''}/>
                                                    {setSwitch('txt_rfc')}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Razon Social<span className="text-danger">*</span></label>
                                                    <input id="txt_razon_social" name='txt_razon_social' className="form-control" type="text" placeholder="Razon Social" 
                                                    defaultValue={datosEmpresa.RAZON_SOCIAL? datosEmpresa.RAZON_SOCIAL: ''}/>
                                                    {setSwitch('txt_razon_social')}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Regimen Fiscal<span className="text-danger">*</span></label>
                                                    <MySelect
                                                        id="list_regimen_fiscal"
                                                        name="list_regimen_fiscal"
                                                        onChange={(v) => setSelectedRegimen(v)}
                                                        options={optionsRegimen}
                                                        value={selectedRegimen}
                                                    />
                                                    {/* <input id="txt_regimen_fiscal" name='txt_regimen_fiscal' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.REGIMEN_FISCAL? datosEmpresa.REGIMEN_FISCAL: ''}/> */}
                                                </div>
                                                {setSwitch('txt_regimen_fiscal')}
                                            </div>
                                            <div className="col-sm-6 col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Receptor uso CFDI<span className="text-danger">*</span></label>
                                                    <MySelect
                                                        id="list_uso_cfdi"
                                                        name="list_uso_cfdi"
                                                        onChange={(v) => setSelectedUsoCfdi(v)}
                                                        options={optionsUsoCfdi}
                                                        value={selectedUsoCfdi}
                                                    />
                                                    {/* <input id="txt_uso_cfdi" name='txt_uso_cfdi' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.RECEPTOR_USO_CFDI? datosEmpresa.RECEPTOR_USO_CFDI: ''}/> */}
                                                </div>
                                                {setSwitch('txt_uso_cfdi')}
                                            </div>
                                            {/* <div className="col-sm-6 col-md-4 mb-3">
                                                <label className="form-label">Tipo CFDI<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input id="txt_tipo_cfdi" name='txt_tipo_cfdi' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.COM_TIPO_CFDI? datosEmpresa.COM_TIPO_CFDI: ''}/>
                                                </div>
                                                {setSwitch('txt_tipo_cfdi')}
                                            </div> */}
                                            {/* <div className="col-sm-6 col-md-4 mb-3">
                                                <label className="form-label">Serie<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input id="txt_serie" name='txt_serie' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.COM_SERIE? datosEmpresa.COM_SERIE: ''}/>
                                                </div>
                                                {setSwitch('txt_serie')}
                                            </div> */}
                                            {/* <div className="col-sm-6 col-md-4 mb-3">
                                                <label className="form-label">Metodo Pago<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input id="txt_metodo_pago" name='txt_metodo_pago' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.COM_METODO_PAGO? datosEmpresa.COM_METODO_PAGO: ''}/>
                                                </div>
                                                {setSwitch('txt_metodo_pago')}
                                            </div> */}
                                            {/* <div className="col-sm-6 col-md-4 mb-3">
                                                <label className="form-label">Forma Pago<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input id="txt_forma_pago" name='txt_forma_pago' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.COM_FORMA_PAGO? datosEmpresa.COM_FORMA_PAGO: ''}/>
                                                </div>
                                                {setSwitch('txt_forma_pago')}
                                            </div> */}
                                            {/* <div className="col-sm-6 col-md-4 mb-3">
                                                <label className="form-label">Condiciones Pago<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input id="txt_condicion_pago" name='txt_condicion_pago' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.COM_CONDICIONES_PAGO? datosEmpresa.COM_CONDICIONES_PAGO: ''}/>
                                                </div>
                                                {setSwitch('txt_condicion_pago')}
                                            </div> */}
                                            {/* <div className="col-sm-6 col-md-4 mb-3">
                                                <label className="form-label">Moneda<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input id="txt_moneda" name='txt_moneda' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.COM_MONEDA? datosEmpresa.COM_MONEDA: ''}/>
                                                </div>
                                                {setSwitch('txt_moneda')}
                                            </div> */}
                                            {/* <div className="col-sm-6 col-md-4 mb-3">
                                                <label className="form-label">Tipo Cambio<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input id="txt_tipo_cambio" name='txt_tipo_cambio' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.COM_TIPO_CAMBIO? datosEmpresa.COM_TIPO_CAMBIO: ''}/>
                                                </div>
                                                {setSwitch('txt_tipo_cambio')}
                                            </div> */}
                                            {/* <div className="col-sm-6 col-md-4 mb-3">
                                                <label className="form-label">Tipo Comprobante<span className="text-danger">*</span></label>
                                                <div className="input-group">
                                                    <input id="txt_tipo_comprobante" name='txt_tipo_comprobante' className="form-control" type="text" 
                                                    placeholder="Regimen Fiscal" defaultValue={datosEmpresa.COM_TIPO_COMPROBANTE? datosEmpresa.COM_TIPO_COMPROBANTE: ''}/>
                                                </div>
                                                {setSwitch('txt_tipo_comprobante')}
                                            </div> */}
                                           <div className="col-sm-6 col-md-6 mb-3">
                                                <label className="form-label">Teléfono 1</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Phone /></span></div>
                                                    <NumericFormat format="(###) ###-####" mask="_" className="form-control" 
                                                    id="tel_direccion1" name="tel_direccion1" defaultValue={datosEmpresa.TELEFONO1? datosEmpresa.TELEFONO1: ''}/>
                                                </div>
                                                {setSwitch('tel_direccion1')}
                                            </div>
                                            <div className="col-sm-6 col-md-6 mb-3">
                                                <label className="form-label">Teléfono 2</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Phone /></span></div>
                                                    <NumericFormat format="(###) ###-####" mask="_" className="form-control" 
                                                    id="tel_direccion2" name="tel_direccion2" defaultValue={datosEmpresa.TELEFONO2? datosEmpresa.TELEFONO2: ''}/>
                                                </div>
                                                {setSwitch('tel_direccion2')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> 
                <div className="col-md-12">
                    <div className="p-3">
                        <div className="d-flex justify-content-end align-items-center">
                            <button className="btn btn-primary btn-lg" type="button" 
                                onClick={() => onClickEditar(['formEmpresa'], ['tel_direccion2', 'txt_num_int', 'list_uso_cfdi', 'list_regimen_fiscal', 'list_estado']) } >Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        );
    };

export default Default;