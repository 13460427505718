import { useEffect } from "react";

const ListPermisos = () => {
    return new Promise((resolve, reject) => {
        fetch('/rt_get_permisos', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(response => {
                console.log('todos los permisos', response.permisos);
                resolve(response.permisos)
            })
    })
}

export const GetPermisos = await ListPermisos()