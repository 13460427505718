import React from 'react'
import Select, { components } from 'react-select'

let selectedValue

const selectChange = (selectedOption) => {
	selectedValue = selectedOption
}

const Option = props => {
	return (
		<components.Option {...props}>
		<div>{props.data.label}</div>
		<div style={{ fontSize: 12, }}>{props.data.subLabel}</div>
		</components.Option>
	);
};

const MySelect = (props) => { 
    return (
	<Select
		id={props.id}
		name={props.id}
		isDisabled={props.isDisabled? true: false} 
		name={props.id} 
		options={props.options}
		onChange={props.onChange? props.onChange: selectChange}
		// onKeyDown={props.onKeyDown? props.onKeyDown: null}
		onInputChange={props.onInputChange? props.onInputChange: null}
		components={{ Option }} 
		placeholder={props.placeholder ? props.placeholder: 'Selecciona una opción...'} 
		defaultValue={props.defaultValue} 
		value={props.value} />
)}

export default MySelect