
import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumbv2'
// import Table from '../common/tabla'
import { Briefcase } from 'react-feather';
import { useNavigate } from 'react-router-dom'
// import { BtnVer, BtnEliminar } from '../common/table_buttons'
// import notie from 'notie'
import Tools from '../../js/tools'

const Default = () => {
    const navigate = useNavigate()
    const [usuario, setUsuario] = useState({})

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_usuario_perfil',
            method: 'POST',
            body:{},
        }, (response) => {
            console.log(response)
            setUsuario(response.datos)
        })
    }, [])
    
    return <Fragment>
        {/* <Breadcrumb icon={Briefcase} title={'Empresa'} parent={['empresa']} /> */}
        <br/>
        <div className="card">
            <div className="col-xl-12 col-md-12">
                <div className="card-header d-flex justify-content-center p-3">
                    <div className='row'>
                        <div className='col-12'><h4><strong>TE DAMOS LA BIENVENIDA {usuario.NOMBRES_UA} {usuario.APELLIDOS_UA}</strong></h4></div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}

export default Default