import React, { Fragment, useState, useEffect } from 'react';
import { Home, Briefcase, BarChart2 } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import DatosInicio from './inicio/datos_inicio';
import DatosEmpresa from './inicio/datos_empresa';
import Breadcrumb from '../../components/common/breadcrumb';
import Toast from '../common/tool_toast'
import Tools from '../../js/tools'

const Default = () =>  {
    let strUrlParent = 'Inicio'
    const [parent, setParent] = useState('graficas')
    const [datosEmpresa, setDatosEmpresa] = useState(null)

    const enviarEmpresa = (formEmpresa) => {
        Tools.toolFetch({ 
            ruta: 'rt_empresa',
            method: 'POST',
            body: formEmpresa
        }, (response) => {
            if(response.estado){
                setDatosEmpresa({
                    empresa: response.resultado[0][0],
                    saldo_total: datosEmpresa.saldo_total
                })
                Toast.onClickToastSuccess({html:<div>Se han actualizado los datos de empresa</div>})
            }else{
                Toast.onClickToastWarn({html:<div>Ocurrio un error al intentar actualizar los datos de emrpesa</div>})
            }
        })
    }

    useEffect(() => {
        let isSubscribed = true
        Tools.toolFetch({ 
            ruta: 'rt_datos_empresa',
            method: 'GET'
        }, (response) => {
            if(isSubscribed){
                setDatosEmpresa({
                    empresa: response.resultado[0][0],
                    saldo_total: response.resultado[1][0]})
            }
        })
        return () => isSubscribed = false
    }, [])

    return (
    <Fragment>
        <Breadcrumb icon={Home} title={strUrlParent} parent={[parent]}/>
        <div className="container-fluid">
            <div className="row theme-tab">
                <Tabs className="col-sm-12">
                    <TabList className="tabs tab-title">
                        <Tab className="current" onClick={() => setParent('graficas')}>
                            <BarChart2 />Gráficas
                        </Tab>
                        <Tab className="current" onClick={() => setParent('datos_empresa')}>
                            <Briefcase />Datos Empresa
                        </Tab>
                    </TabList>
                    <div className="tab-content-cls">
                        <TabPanel>
                            <DatosInicio datosEmpresa={datosEmpresa? datosEmpresa.empresa: {}}  saldoTotal={datosEmpresa? datosEmpresa.saldo_total: {}}/>
                        </TabPanel>
                        <TabPanel>
                            <DatosEmpresa datosEmpresa={datosEmpresa? datosEmpresa.empresa: {}} enviarEmpresa={enviarEmpresa} />
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </div>
    </Fragment>)
}


export default Default;