import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import Table from '../common/tabla'
import { GetPermisos } from '../common/getPermisos';
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({
    listArtIns,
    setListArtIns,
    formRec,
    esEditar,
    esHistorial,
    setValueTab,
    setArtInsPag }) => {
    const [formFaIn, setFormFaIn] = useState({})
    const [tipoVal, setTipoVal] = useState(0)
    const [esRefresh, setEsRefresh] = useState(true)
    const [textot, setTextot] = useState('asadaw')
    const [check, setCheck] = useState(true)
    const navigate = useNavigate()
    const params = useParams();

    // useEffect(() => {
    //     if (!esEditar) return

    //     Tools.toolFetch({
    //         ruta: 'rt_familias_insumos_detalle',
    //         method: 'POST',
    //         body: params,
    //     }, (response) => {
    //         console.log(response);

    //         if (response.status === 'OK') {
    //             setFormFaIn(response.result[0])
    //             setTipoVal(response.result[0].TIPO_RRS)
    //         }
    //     })
    // }, [])

    const operacionRegistro = (d, obj, v) => {
        let registro = Object.assign({}, listArtIns[d.ID_INA])
        registro[obj] = parseFloat(v)

        let inv_in = parseFloat(registro.inv_in ? registro.inv_in : 0)
        let compra = parseFloat(registro.compra ? registro.compra : 0)
        let merma = parseFloat(registro.merma ? registro.merma : 0)
        let produccion = parseFloat(registro.produccion ? registro.produccion : 0)
        let total = parseFloat(formRec.COMPRA_RE ? formRec.COMPRA_RE : 0)
        let consumo = parseFloat(produccion * total)

        let inv_fin = inv_in + compra - merma - consumo
        registro = { ...registro, inv_in, compra, produccion, merma, consumo, inv_fin }

        setListArtIns(l => {
            l[d.ID_INA] = registro
            return l
        })

        return registro
    }

    return (
        <Fragment>
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    <div className="card-header d-flex justify-content-center p-3">
                        <h4>Insumos/Articulos</h4>
                    </div>
                    <div className="mt-3 px-4">
                        {!esHistorial ?
                            <div className='input-field select'>
                                <Select
                                    data-label="Auditor"
                                    defaultOptOff={true}
                                    datos={[{ clave: 0, label: 'Seleccionados' }, { clave: 1, label: 'Insumos' }, { clave: 2, label: 'Articulos' }]}
                                    onChange={(e) => {
                                        setTipoVal(parseInt(e.item.clave))
                                        console.log(listArtIns);
                                    }}
                                    spellcheck="false"
                                />
                                <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Articulos/Insumos <span className="text-danger">*</span></label>
                            </div>
                            : ''}
                    </div>
                    <div className="mt-3 px-4">
                        {tipoVal == 0 ?
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                headers={[
                                    {
                                        nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                            return <div style={{ textAlign: 'center' }}>
                                                <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.NOMBRE_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'TÍTULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.TITULO_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    // {
                                    //     nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                    //         return <div>
                                    //             <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                    //         </div>
                                    //     }
                                    // },
                                    // {
                                    //     nombre: 'INVENTARIO INICIAL', tipo: 'input', texto: 'inv_in', valueOp: (d, v) => {
                                    //         return operacionRegistro(d, 'inv_in', v)
                                    //     }
                                    // },
                                    // {
                                    //     nombre: 'COMPRA', tipo: 'input', texto: 'compra', valueOp: (d, v) => {
                                    //         return operacionRegistro(d, 'compra', v)
                                    //     }
                                    // },
                                    // {
                                    //     nombre: 'PRODUCCION ', tipo: 'input', texto: 'produccion', valueOp: (d, v) => {
                                    //         return operacionRegistro(d, 'produccion', v)
                                    //     }
                                    // },
                                    // {
                                    //     nombre: 'MERMA', tipo: 'input', texto: 'merma', valueOp: (d, v) => {
                                    //         return operacionRegistro(d, 'merma', v)
                                    //     }
                                    // },
                                    // {
                                    //     nombre: 'PALEY1', tipo: 'input', texto: 'paley1', valueOp: (d, v) => {
                                    //         return operacionRegistro(d, 'paley1', v)
                                    //     }
                                    // },
                                    // {
                                    //     nombre: 'PALEY2', tipo: 'input', texto: 'paley2', valueOp: (d, v) => {
                                    //         return operacionRegistro(d, 'paley2', v)
                                    //     }
                                    // },
                                    // {
                                    //     nombre: 'CONOSUMO', tipo: 'input', texto: 'consumo', disabled: true
                                    // },
                                    // {
                                    //     nombre: 'INVENTARIO FINAL', tipo: 'input', texto: 'inv_fin', disabled: true
                                    // },
                                    !esHistorial ? {
                                        nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                            setListArtIns(l => {
                                                l[d.ID_INA] = !l[d.ID_INA] ? { id: d.ID_INA } : false
                                                return l
                                            })
                                            setEsRefresh(l => !l)
                                        }
                                    } : {},
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'/rt_recetas_artins_list'}
                                refresh={esRefresh}
                                body={{ tipo: 0, seleccionados: listArtIns }}
                                rowsPerPage={20}
                                paginadorTop={true}
                            >
                            </Table> : ''
                        }
                        {tipoVal == 1 ?
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                headers={[
                                    {
                                        nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                            return <div style={{ textAlign: 'center' }}>
                                                <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.NOMBRE_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'TITULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.TITULO_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                            setListArtIns(l => {
                                                l[d.ID_INA] = !l[d.ID_INA] ? { id: d.ID_INA } : false
                                                return l
                                            })
                                        }
                                    },
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                // path={'/rt_familias_insumos_list'}
                                path={'/rt_recetas_artins_list'}
                                // refresh={esRefresh}
                                body={{ tipo: 1, seleccionados: listArtIns }}
                                rowsPerPage={20}
                                paginadorTop={true}
                            // results={(rows) => {
                            //     if (rows) {
                            //         rows.forEach(v => {
                            //             setListCheck(l => {
                            //                 l[v.ID_INA] = v.CHECKED
                            //                 return l
                            //             })
                            //         });
                            //     }
                            // }}
                            >
                            </Table> : ''
                        }
                        {tipoVal === 2 ?
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                headers={[
                                    {
                                        nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                            return <div style={{ textAlign: 'center' }}>
                                                <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.NOMBRE_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'TITULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.TITULO_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    // {
                                    //     nombre: 'SKU', tipo: 'html', texto: 'SKU_INA', html: (d) => {
                                    //         return <div>
                                    //             <strong>{d.SKU_INA}</strong><br />
                                    //         </div>
                                    //     }
                                    // },
                                    {
                                        nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                            setListArtIns(l => {
                                                l[d.ID_INA] = !l[d.ID_INA] ? { id: d.ID_INA } : false
                                                return l
                                            })
                                        }
                                    },
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                // path={'/rt_familias_insumos_list'}
                                path={'/rt_recetas_artins_list'}
                                // refresh={esRefresh}
                                body={{ tipo: 2, seleccionados: listArtIns }}
                                rowsPerPage={20}
                                paginadorTop={true}
                            >
                            </Table> : ''
                        }
                    </div>
                </div>
                <div className="col-md-12 mb-3">
                    <div className="justify-content-end d-flex">
                        {/* <button
                            className='btn-nuevo'
                            type='button'
                            // onClick={onClickForm}
                            onClick={() => { setValueTab('0') }}
                        >
                            <span className="text">Anterior</span>
                            <div className="icon-container">
                                <div className="icon icon--left">
                                </div>
                                <div className="icon icon--right">
                                </div>
                            </div>
                        </button> */}
                        {(!esHistorial && esEditar && (GetPermisos.find(permiso => [2061].includes(permiso)) ? true : false)) ||
                            (!esEditar && (GetPermisos.find(permiso => [31].includes(permiso)) ? true : false)) ?
                            <button
                                className='btn-nuevo'
                                type='button'
                                onClick={() => { setArtInsPag(l => !l) }}
                            >
                                <span className="text">Guardar</span>
                                <div className="icon-container">
                                    <div className="icon icon--left">
                                    </div>
                                    <div className="icon icon--right">
                                    </div>
                                </div>
                            </button> : ''}
                    </div>
                </div>
            </div >
        </Fragment >
    );
}

export default Default