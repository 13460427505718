import Tools from '../js/tools'

const permisos = () => {
    return new Promise((resolve, reject) => {
        Tools.toolFetch({
            ruta: 'rt_verificar_permisos',
            method: 'POST',
        }, (response) => {
            resolve(response.permisos)
        })
    })
}

export const sesion = await permisos()