import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import { GetPermisos } from '../common/getPermisos';
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({ esEditar }) => {

    const [formInv, setFormInv] = useState({})
    const [ctgDias, setCtgDias] = useState([])
    const [ctgTipos, setCtgTipos] = useState([])
    const [valDia, setValDia] = useState([])
    const [valTipo, setValTipo] = useState(0)
    const [mostrarDias, setMostrarDias] = useState(false)
    // const [ctgEstados, setCtgEstados] = useState([])
    // const [ctgCiudades, setCtgCiudades] = useState([])
    // const [valCiudad, setValCiudad] = useState([])
    const navigate = useNavigate()
    const params = useParams();
    const [catalogos, setCatalogos] = useState({})

    useEffect(() => {
        return Tools.toolFetch({
            ruta: 'rt_tipo_inventario_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            if (response.status === 'OK') {
                setCtgDias(response.dias)
                setCtgTipos(response.tipos)
                if (esEditar) {
                    setFormInv(response.result[0])
                    setValDia(JSON.parse(response.result[0].DIAS_TII))
                    setValTipo(response.result[0].TIPO_TII)
                    setMostrarDias(response.result[0].TIPO_TII)
                } else {
                    setFormInv({})
                    setValDia([])
                    setValTipo(0)
                    setMostrarDias(false)
                }
            }
        })

    }, [esEditar])

    const onClickInv = () => {
        const form = Tools.getForm('formInv', { ignore: ['txt_num_ext'] })
        console.log(form.data.clave_tipo, valDia);
        if (form.data.clave_tipo == 1 && valDia.length === 0) form.isEmtyCount++

        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        form.data.id = params.id
        form.data.list_dias = valDia
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_tipo_inventario_nuevo' : 'rt_tipo_inventario_editar',
            method: 'POST',
            body: form.data,
        }, (response) => {
            console.log(response)

            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                window.location.href = '/tipo-inventario'
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const onChangeTipo = (e) => {
        e.item.clave === 1 ? setMostrarDias(true) : setMostrarDias(false)
        setValTipo(e.item.clave)
    }

    return (
        <Fragment>
            <Breadcrumb icon={Briefcase} title={'Tipos inventario'} parent={[{ titulo: 'tipo de inventaraio', link: '/tipo-inventario' }, { titulo: `${esEditar ? 'editar' : 'nuevo'} tipo de inventaraio`, link: `/tipo-inventario/${esEditar ? params.id : 'nuevo'}` }]} />
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formInv' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/tipo-inventario')
                                }}></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVO'} TIPO INVENTARIOS</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales del tipo inventario</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                data-label="Nombre"
                                                autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                defaultValue={formInv ? formInv.NOMBRE_TII : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-book" aria-hidden="true"></i> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                data-label="Titulo"
                                                autoComplete='off'
                                                id="txt_titulo" name="txt_titulo"
                                                type="text"
                                                defaultValue={formInv ? formInv.TITULO_TII : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Título<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (Título)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                // data-label="Días"
                                                id="clave_tipo"
                                                name="clave_tipo"
                                                datos={ctgTipos}
                                                value={valTipo}
                                                onChange={onChangeTipo}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Inventario"></span> Tipo de inventario<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_tipo" style={{ display: 'none' }}><strong>Falta completar (Tipo de inventario)</strong></span>
                                    </div>
                                    {mostrarDias ?
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationTooltip02">Días<span className="text-danger">*</span></label>
                                            <div className="input-group h-100">
                                                <div className="input-group-prepend"></div>
                                                <SelectSimple
                                                    id={'list_dias'}
                                                    // name={'list_dias'}
                                                    data-label="Dias"
                                                    isMulti={true}
                                                    options={ctgDias}
                                                    className={'selectnumeric'}
                                                    // value={preciosSuc.map((v) => ({label: v.NOMBRE_SUC, value: v.ID_SUC_PRE}))}
                                                    value={valDia}
                                                    onChange={(datos) => {
                                                        console.log('datos', datos);
                                                        console.log(ctgDias);
                                                        setValDia(datos)
                                                    }}
                                                    required
                                                    spellcheck="false"
                                                />
                                            </div>
                                            <span className="text-danger txtVacios list_dias" style={{ display: 'none' }}><strong>Falta completar (Días)</strong></span>
                                        </div> : ''}
                                    <div className="col-md-12 mb-3">
                                        <div className='input-field'>
                                            <textarea
                                                data-label="Descripción"
                                                autoComplete='off'
                                                id="txt_descripcion" name="txt_descripcion"
                                                type="text"
                                                defaultValue={formInv ? formInv.DESCRIPCION_TII : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Descripción<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_descripcion" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            {(esEditar && (GetPermisos.find(permiso => [2059].includes(permiso)) ? true : false)) ||
                                                (!esEditar && (GetPermisos.find(permiso => [25].includes(permiso)) ? true : false)) ?
                                                <button className='btn-nuevo' type='button' onClick={onClickInv}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default