
export const objetoFormularios = {
    validarFormulario: (formArray, camposIngnore, callback) => {
        let jsonForms = {}, form, json = {}, jsonVacio = {}, jsonTel = {}, jsonList = {}, jsonCorreo = {}, phone = 0
        formArray.forEach((v) => {
            form = new FormData(document.getElementById(v))
            form.forEach((v, k) => {
                //FORMATEO DE DATOS
                if (k.substr(0, k.indexOf('_')) === 'chk')
                    json[k] = v

                if (k.substr(0, k.indexOf('_')) === 'float')
                    json[k] = Number(v.replace(/,/g, ''))

                if (k.substr(0, k.indexOf('_')) === 'txt')
                    json[k] = v

                if (k.substr(0, k.indexOf('_')) === 'correo') {
                    json[k] = v
                    jsonCorreo[k] = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(v)
                }

                if (k.substr(0, k.indexOf('_')) === 'list') {
                    json[k] = v
                    jsonList[k] = camposIngnore.find((v2) => v2 === k) ? true : json[k] !== 0
                }

                if (k.substr(0, k.indexOf('_')) === 'tel' || k.substr(0, k.indexOf('_')) === 'cel') {
                    json[k] = v.replace(/[(]/gi, '').replace(/[)]/gi, '').replace(/[-]/gi, '').replace(/[ ]/gi, '').replace(/(_)/gi, '')
                }

                //VALIDACIONES
                if (camposIngnore.filter((v2) => v2 === k).length === 0) {
                    if (k.substr(0, k.indexOf('_')) === 'float')
                        jsonVacio[k] = v !== ''

                    if (k.substr(0, k.indexOf('_')) === 'txt')
                        jsonVacio[k] = v !== ''

                    if (k.substr(0, k.indexOf('_')) === 'correo') {
                        jsonVacio[k] = v !== ''
                    }

                    if (k.substr(0, k.indexOf('_')) === 'tel' || k.substr(0, k.indexOf('_')) === 'cel') {
                        phone = v.replace(/ /g, '').replace(/[(]/g, '').replace(/[)]/g, '').replace(/[-]/g, '')
                            .replace(/(_)/g, '').length
                        jsonVacio[k] = phone > 0
                        jsonTel[k] = phone === 10 ? true : false
                    }
                } else if (camposIngnore.filter((v2) => v2 === k).length > 0) {
                    if (k.substr(0, k.indexOf('_')) === 'tel' || k.substr(0, k.indexOf('_')) === 'cel') {
                        phone = v.replace(/ /g, '').replace(/[(]/g, '').replace(/[)]/g, '').replace(/[-]/g, '')
                            .replace(/(_)/g, '').length
                        jsonTel[k] = phone === 0 ? true : phone === 10
                    }
                }
            })

            jsonForms[v] = json
            json = {}
        })

        callback({
            jsonForms,
            jsonVacio,
            jsonTel,
            jsonList,
            jsonCorreo
        })
    },
    onClickAgregarDatos: (response, callback) => {
        let arrJsonTel = Object.values(response.jsonTel),
            arrJsonList = Object.values(response.jsonList),
            arrJsonCorreo = Object.values(response.jsonCorreo),
            arrJsonVacio = Object.values(response.jsonVacio)

        let jsonTel = response.jsonTel,
            jsonList = response.jsonList,
            jsonCorreo = response.jsonCorreo,
            jsonVacio = response.jsonVacio

        let vacios = arrJsonVacio.find((v) => v === false) == undefined,
            tel = arrJsonTel.length > 0 ? (arrJsonTel.find((v) => v === false) == undefined) : 2,
            list = arrJsonList.length > 0 ? (arrJsonList.find((v) => v === false) == undefined) : 2,
            correo = arrJsonCorreo.length > 0 ? (arrJsonCorreo.find((v) => v === false) == undefined) : 2

        if ((vacios && (tel === true || tel === 2) && (list === true || list === 2) && (correo === true || correo === 2))) {
            let jsonForms = response.jsonForms
            jsonForms['jsonTel'] = jsonTel
            jsonForms['jsonList'] = jsonList
            jsonForms['jsonCorreo'] = jsonCorreo
            jsonForms['jsonVacio'] = jsonVacio
            jsonForms['estatusMostrarMsj'] = false
            callback({
                estatus: 1,
                jsonForms
            })
        } else {
            callback({
                estatus: 0,
                msj: `<div>
                        ${vacios ? '' : "<h6>- Favor de completar los campos requeridos marcados con <span style={{color: 'red'}}>(*)</span>.</h6>"}
                        ${(tel || tel === 2) ? '' : "<h6>- El teléfono o celular deben de tener 10 dígitos.</h6>"}
                        ${list || list === 2 ? '' : "<h6>- Debe seleccionar un elemento de la lista.</h6>"}
                        ${correo || correo === 2 ? '' : "<h6>- El correo electrónico es incorrecto.</h6>"}
                    </div>`,
                jsonForms: {
                    estatusMostrarMsj: true,
                    jsonTel,
                    jsonList,
                    jsonCorreo,
                    jsonVacio
                }
            })
        }
    }
}