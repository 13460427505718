import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import { Alert } from 'reactstrap';
import Table from '../common/tabla'
import Table2 from '../common/tabla2'
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';
import { GetPermisos } from '../common/getPermisos';

const Default = () => {
    const [info, setInfo] = useState({})
    const [esRefresh, setEsRefresh] = useState(true)
    const [listArtIns, setListArtIns] = useState({})
    const [mostrarTabla, setMostrarTabla] = useState('')
    const navigate = useNavigate()
    const params = useParams()
    const estatus = [
        <Alert color="warning" className="dark">
            Requisición pendiente de autorizar
        </Alert>,
        <Alert color="info" className="dark">
            Requisición autorizada
        </Alert>,
        <Alert color="danger" className="dark">
            Requisición en proceso
        </Alert>,
        <Alert color="success" className="dark">
            Requisición completada
        </Alert>,
        <Alert color="primary" className="dark">
            Requisición cancelada
        </Alert>,
    ]

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_requisiciones_detalle_autorizar',
            method: 'POST',
            body: {
                cry: params.crypt
            },
        }, (response) => {
            if (response.status === 'OK') {
                const artInsList = {}

                response.result[1].forEach(v => {
                    artInsList[`${v.ID_INA_RQP}_${v.ID_PRV_RQP}`] = { id: v.ID_INA_RQP, id_prv: v.ID_PRV_RQP, cantidad_rqp: v.CANTIDAD_RQP, cantidad_auth: v.CANTIDAD_AUTH, comentarios: '', activo: 1 }
                });

                setInfo(response.result[0][0])
                setListArtIns(artInsList)
                setEsRefresh(!esRefresh)
                // setMostrarTabla(getTabla(artInsList, response.result[0][0].ESTATUS_RQI))
                // setEsRefresh(!esRefresh) 
            }
        })
    }, [])


    const onClickForm = () => {
        const articulosInsumos = Object.values(listArtIns).filter((v) => v)
        const cantidadVacias = articulosInsumos.filter(v => !v.cantidad && v.activo)
        const comentariosVacios = articulosInsumos.filter(v => v.comentarios === '' && !v.activo)

        if (cantidadVacias.length > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Agregue una cantidad en todos los Articulos/Insumos seleccionados`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (comentariosVacios.length > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Agregue un comentario en ${comentariosVacios > 1 ? 'los Articulos/Insumos seleccionados' : 'el Articulo/Insumo seleccionado'}`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        notie.confirm({
            submitText: 'Aceptar',
            cancelText: 'Cancelar',
            text: `Al autorizar la requisición se le notificará al almacen surtidor ¿Desea continuar?`,
            cancelCallback: function () {
            },
            submitCallback: function () {
                Tools.toolFetch({
                    ruta: 'rt_requisiciones_estatus_enviar',
                    method: 'POST',
                    body: { id: info.ID_RQI, articulosInsumos, estatus: 2 },
                }, (response) => {
                    console.log(response);

                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }
                    if (response.estatus === 'ERROR-EMAIL') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `La requisición ha sido autorizada, no obstante a ocurrido un problema al enviar el correo, por favor intentelo mas tarde`,
                            // stay: Boolean, // optional, default = false
                            time: 6, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    const artInsList = {}
                    response.result[1].forEach(v => {
                        artInsList[`${v.ID_INA_RQP}_${v.ID_PRV_RQP}`] = {
                            id: v.ID_INA_RQP,
                            id_prv: v.ID_PRV_RQP,
                            // cantidad: rutaPlantillas ? v.CANTIDAD_RQP : v.CANTIDAD,
                            cantidad_rqp: v.CANTIDAD_RQP,
                            cantidad_auth: v.CANTIDAD_AUTH_RQP,
                            cantidad_proceso: v.CANTIDAD_PROCESO_RQP,
                            comentarios: v.COMENTARIOS,
                            activo: v.ACTIVO_RQP
                        }
                    });

                    setListArtIns(artInsList)
                    setInfo(l => ({ ...l, ESTATUS_RQI: response.result[0][0].ESTATUS_RQI }))
                    setEsRefresh(!esRefresh)
                    // setMostrarTabla(getTabla(artInsList, response.result[0][0].ESTATUS_RQI))

                    notie.alert({
                        type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `La requisición ha sido autorizada correctamente`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                })
            }
        })
    }

    return (
        <Fragment>
            <br />
            <div className='container-fluid'>
                {
                    <Fragment>
                        {estatus[info.ESTATUS_RQI - 1]}
                    </Fragment>
                }
                <form id='formReq' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className='card'>
                        <div className="card-body">
                            <div className='row'>
                                <div className="col-md-12 mb-3">
                                    <h6><strong>Almacenes</strong></h6>
                                    <hr />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className='input-field'>
                                        <input autoComplete='off'
                                            type="text"
                                            value={info.USUARIO_SOLICITANTE}
                                            onChange={null}
                                            spellcheck="false"
                                        />
                                        <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Solicitante<span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className='input-field'>
                                        <input autoComplete='off'
                                            type="text"
                                            value={info.ALMACEN_SOLICITANTE}
                                            onChange={null}
                                            spellcheck="false"
                                        />
                                        <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Almacen solicitante<span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className='input-field'>
                                        <input autoComplete='off'
                                            type="text"
                                            value={info.ALMACEN_SURTIDOR}
                                            onChange={null}
                                            spellcheck="false"
                                        />
                                        <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Almacen surtidor<span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                {info.COMENTARIOS_RQI ?
                                    <div className="col-md-12 mb-3">
                                        <div className='input-field'>
                                            <textarea autoComplete='off'
                                                type="text"
                                                value={info.COMENTARIOS_RQI}
                                                onChange={null}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Descripción<span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    <div className="card-header d-flex justify-content-center p-3">
                        <h4>Insumos/Articulos</h4>
                    </div>
                    <div className="mt-3 px-4">
                        <Table
                            placeholderSearch={'Buscar seleccionados...'}
                            noRutaDinamica={true}
                            cargando={false}
                            headers={[
                                {
                                    nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                        return <div style={{ textAlign: 'center' }}>
                                            <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                        return <div>
                                            <strong>{d.NOMBRE_INA}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'PROVEEDOR', tipo: 'html', texto: 'TITULO_PRV', html: (d) => {
                                        return <div>
                                            <strong>{d.TITULO_PRV}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                        return <div style={{ width: '8em' }}>
                                            <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'CANTIDAD INICIAL', tipo: 'input', texto: 'CANTIDAD_INICIAL', disabled: true
                                },
                                {
                                    nombre: 'CANTIDAD AUTORIZADA', tipo: 'input', texto: 'CANTIDAD_AUTH', disabled: info.ESTATUS_RQI === 1 ? false : true, valueOp: (d, v) => {
                                        const cantidad = v > 0 ? v : 1

                                        setListArtIns(l => {
                                            l[`${d.ID_INA}_${d.ID_PRV}`].cantidad = cantidad
                                            return l
                                        })
                                        return { CANTIDAD_AUTH: cantidad }
                                    }
                                },
                                {
                                    nombre: 'MEDIDA MAXIMA', tipo: 'html', texto: 'NOMBRE_MAX_CU', html: (d) => {
                                        return <div style={{ width: '8em' }}>
                                            <strong>{d.NOMBRE_MAX_CU}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'MEDIDA MINIMA', tipo: 'html', texto: 'NOMBRE_MIN_CU', html: (d) => {
                                        return <div style={{ width: '8em' }}>
                                            <strong>{d.NOMBRE_MIN_CU}</strong><br />
                                        </div>
                                    }
                                },
                                info.ESTATUS_RQI === 1 ? {
                                    nombre: 'SELECCIONAR',
                                    tipo: 'check',
                                    texto: 'ID_INA',
                                    inputCom: true,
                                    callback: (d) => {
                                        setListArtIns(l => {
                                            // l[`${d.ID_INA}_${d.ID_PRV}`] = !l[`${d.ID_INA}_${d.ID_PRV}`] ? { id: d.ID_INA, id_prv: d.ID_PRV, cantidad: 0 } : false
                                            l[`${d.ID_INA}_${d.ID_PRV}`] = { ...l[`${d.ID_INA}_${d.ID_PRV}`], activo: !l[`${d.ID_INA}_${d.ID_PRV}`].activo ? 1 : 0 }

                                            console.log(l);
                                            return l
                                        })
                                    },
                                } : {}
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            // path={'/rt_familias_insumos_list'}
                            path={'/rt_requisiciones_artins_list'}
                            refresh={esRefresh}
                            body={{ tipo: 0, valPrv: 0, seleccionados: listArtIns }
                            }
                            callBackComentario={(d) => {
                                setListArtIns(l => {
                                    l[`${d.ID_INA}_${d.ID_PRV}`] = { ...l[`${d.ID_INA}_${d.ID_PRV}`], comentarios: d.comentario }

                                    console.log(l);
                                    return l
                                })
                            }}
                            rowsPerPage={20}
                            paginadorTop={true}
                        />
                    </div>
                    {[1, 2].includes(info.ESTATUS_RQI) ?
                        <div className="col-md-12 mb-3">
                            <div className="justify-content-end d-flex">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <button className='btn-nuevo' type='button'
                                        onClick={onClickForm}
                                    >
                                        <span className="text">{info.ESTATUS_RQI === 1 ? 'Autorizar' : 'Reenviar'}</span>
                                        <div className="icon-container">
                                            <div className="icon icon--left">
                                            </div>
                                            <div className="icon icon--right">
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        : ''}
                </div>
            </div >
        </Fragment >
    );
}

export default Default