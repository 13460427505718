import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { User, LogOut } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react'
import Tools from '../../../js/tools'
import notie from 'notie'

const UserMenu = () => {
    const [profile, setProfile] = useState('');
    const authenticated = JSON.parse(localStorage.getItem("authenticated"))
    const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))

    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || man);
    }, []);

    const onClickCerrarSesion = () => {
        Tools.toolFetch({
            ruta: 'rt_login_cerrar_sesion',
            method: 'POST',
            body: {},
        }, (response) => {
            setTimeout(() => window.location.href = '/login', 2000)
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Cerrando sesión...`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="d-flex align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={authenticated ? auth0_profile.picture : profile} alt="header-user" />
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><Link to={`${process.env.PUBLIC_URL}/perfil`}><User />Editar perfil</Link></li>
                    <li><span onClick={onClickCerrarSesion} ><LogOut />Cerrar sesión</span></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;