import React, { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Briefcase } from 'react-feather';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()
    const params = useParams()

    const eliminar = (data) => {
        data.idPrv = params.id

        Tools.toolFetch({
            ruta: 'rt_proveedores_contacto_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            {/* <Breadcrumb icon={Briefcase} title={'Proveedores'} parent={['proveedores']} /> */}
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    <div className="card-header d-flex justify-content-center p-3">
                        <h4>Contacto Proveedores</h4>
                    </div>
                    <div className="mt-3 px-4">
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                /* {<button
                                    className="btn btn-secondary btn-sm"
                                    type="button"
                                    onClick={() => {
                                        navigate('/proveedores/nuevo')
                                    }}
                                > Nuevo
                                </button>} */
                                <div className="btn-container">
                                    <button className='btn-nuevo' onClick={() => {
                                        navigate('/proveedores-contacto/nuevo/' + params.id)
                                    }}>
                                        <span className="text">Nuevo</span>
                                        <div className="icon-container">
                                            <div className="icon icon--left">
                                            </div>
                                            <div className="icon icon--right">
                                            </div>
                                        </div>
                                    </button>
                                </div>}
                            headers={[
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_PRC', html: (d) => {
                                        return <div>
                                            <strong>{d.NOMBRE_PRC}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'CORREO', tipo: 'html', texto: 'CORREO_PRC', html: (d) => {
                                        return <div>
                                            <strong>{d.CORREO_PRC}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'TELEFONO', tipo: 'html', texto: 'TELEFONO_PRC', html: (d) => {
                                        return <div>
                                            <strong>{d.TELEFONO_PRC}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'CELULAR', tipo: 'html', texto: 'CELULAR_PRC', html: (d) => {
                                        return <div>
                                            <strong>{d.CELULAR_PRC}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                    html: (d) => {
                                        return <div>
                                            <BtnVer
                                                onClick={() => {
                                                    navigate(`/proveedores-contacto/editar/${params.id}/${d.ID_PRC}`)
                                                }}
                                            />
                                            {(GetPermisos.find(permiso => [2035].includes(permiso)) ? true : false) ?
                                                <BtnEliminar
                                                    onClick={() => {
                                                        notie.confirm({
                                                            text: `Se eliminara <strong>${d.NOMBRE_PRC}</strong> ¿estas de acuerdo?`,
                                                            submitText: 'Si, eliminar',
                                                            cancelText: 'No, Cancelar',
                                                            cancelCallback: function (value) {
                                                            },
                                                            submitCallback: function (value) {
                                                                eliminar({ id_prc: d.ID_PRC })
                                                            },
                                                        })
                                                    }}
                                                /> : ''
                                            }
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_proveedores_contacto_list'}
                            refresh={esRefresh}
                            body={params}
                            rowsPerPage={20}
                            paginadorTop={true}
                        >
                        </Table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Default;