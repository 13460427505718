// import React from 'react';

// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// let toastId = null, estatusModal = true

// const notifyWarn = (html) => toastId = toast.warn(<div style={{color: '#4B0000', padding: 20, width: '100%'}}>
//     <div style={{width: '100%', fontSize: 25, textAlign: "center"}}>
//         <i className="fa fa-exclamation-triangle"  style={{color: 'red'}}></i>
//     </div>
//     {html}
// </div>);

// const notifySucces= (html) => toastId = toast.success(<div style={{padding: 20, width: '100%'}}>
//     <div style={{width: '100%', fontSize: 25, textAlign: "center"}}>
//         <i className="fa fa-check-circle"  style={{color: 'green'}}></i>
//     </div>
//     {html}
// </div>);

// const onClickToastWarn = (datos) =>{
//     if(estatusModal){
//         estatusModal = false
//         notifyWarn(datos.html)
//     }

//     toast.update(toastId, {
//         onClose: () => {
//             estatusModal = true
//         },
//         autoClose: datos.autoClose,
//         draggablePercent: 80
//     });
// }

// const onClickToastSuccess = (datos) => {
//     if(estatusModal){
//         estatusModal = false
//         notifySucces(datos.html)
//     }

//     toast.update(toastId, {
//         onClose: () => {
//             estatusModal = true
//         },
//         autoClose: datos.autoClose,
//         draggablePercent: 80
//     });
// }

// export default { onClickToastWarn, onClickToastSuccess};

import React from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastId = null, estatusModal = true

const notifyWarn = (html, params, esIcon) => toastId = toast.warn(<div style={{color: '#4B0000', padding: 20, width: '100%'}}>
    {esIcon?<div style={{width: '100%', fontSize: 25, textAlign: "center"}}>
        <i className="fa fa-exclamation-triangle"  style={{color: 'red'}}></i>
    </div>: ''}
    {html}
</div>, params);

const notifySucces= (html) => toastId = toast.success(<div style={{padding: 20, width: '100%'}}>
    <div style={{width: '100%', fontSize: 25, textAlign: "center"}}>
        <i className="fa fa-check-circle"  style={{color: 'green'}}></i>
    </div>
    {html}
</div>);

const onClickToastWarn = (datos) =>{
    if(estatusModal){
        estatusModal = false
        notifyWarn(datos.html, datos.params? datos.params: {}, datos.esIcon)
    }

    toast.update(toastId, {
        onClose: () => {
            estatusModal = true
        },
        autoClose: datos.autoClose,
        draggablePercent: 80
    });
}

const onClickToastSuccess = (datos) => {
    if(estatusModal){
        estatusModal = false
        notifySucces(datos.html, datos.params? datos.params: {})
    }

    toast.update(toastId, {
        onClose: () => {
            estatusModal = true
        },
        autoClose: datos.autoClose,
        draggablePercent: 80
    });
}

export default { onClickToastWarn, onClickToastSuccess};