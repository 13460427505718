import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Briefcase } from 'react-feather';
import notie from 'notie';
import Tools from '../../js/tools';
import Breadcrumb from '../common/breadcrumbv2';

const Default = (props) => {
    /* ==================== HOOKS ==================== */
    const navigate = useNavigate()

    const [catalogos, setCatalogos] = useState({
        empresas: [],
        tipos: [],
        permisosSecciones: []
    });

    /* ==================== CONSTANTES ==================== */

    /* ==================== USE EFFECT ==================== */
    useEffect(() => {
        fetch('../../rt_catalogos_agregar_usuario', {
            method: 'POST',
            body: JSON.stringify({}),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (res) { return res.json(); })
            .then(function (res) {
                console.log('res', res);
                if (res.estatus === 'OK') {
                    let seccionesPermisos = groupArrayByKey(res.datos.permisos, 'id_sec', 'id');

                    let sec = res.datos.secciones.map(seccion => {
                        return {
                            id: seccion.id,
                            titulo: seccion.nombre,
                            permisos: seccionesPermisos[seccion.id].array
                        }
                    });

                    res.datos.permisosSecciones = sec;
                    res.datos.secciones = null;

                    setCatalogos(res.datos);
                    console.log('res.datos', res.datos);
                }

                else
                    throw Error(res.estatus);
            }).catch(function (err) {
                notie.alert({ text: 'No se pudo obtener los catálogos, inténtelo más tarde', type: 4, time: 5 });
                console.log('err', err);
            })
    }, [])


    /* ==================== FUNCIONES ==================== */
    const groupArrayByKey = (xs, key, param) => {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || { key: x[param], array: [] }).array.push(x);
            return rv;
        }, {});
    }

    const enviarDatosFormulario = () => {
        let datos = Tools.getForm('form_usuario', { ignore: ['input_CELULAR'] });
        console.log('datos formulario', datos);

        if (datos.isEmtyCount > 0) {
            notie.alert({ text: `El campo ${datos.isEmtyLabel[datos.keys[0]]} es requerido`, type: 3, time: 4 });
            return;
        }

        let form_permisos = new FormData(document.getElementById('form_permisos'));
        let permisos_form = [], permiso_split, e;
        for (e of form_permisos.entries()) {
            permiso_split = e[0].split('_');
            permisos_form.push({
                id: permiso_split[2]
            });
        };

        datos.data['permisos'] = JSON.stringify(permisos_form);
        /*         let body = {}
        
                Object.keys(datos.data).foreach(key => {
                    body[key.replace(/input_/g, '')] = datos.data[key];
                }) */

        fetch('../rt_agregar_usuario_permisos', {
            method: 'POST',
            body: JSON.stringify(datos.data),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(res => {
                if (res.estatus === 'OK') {
                    if (res.datos.ESTATUS === 'AGREGADO') {
                        notie.alert({ text: 'Usuario creado correctamente', type: 1, time: 5 });
                        navigate('/usuarios-cms')
                    } else {
                        notie.alert({ text: 'El usuario ya se encuentra registrado', type: 2, time: 5 });
                    }
                } else {
                    throw Error(res.estatus)
                }
            }).catch(err => {
                notie.alert({ text: 'No se pudo agregar el usuario, inténtelo más tarde', type: 3, time: 6 });
                console.log(err);
            })
    }

    const ComponentePermisos = (propsPermisos) => {
        console.log('propsPermisos', propsPermisos);
        return (
            <Fragment>
                <div className='card-permisos'>
                    <div><h5>{propsPermisos.titulo}</h5></div>
                    <hr className='solid'></hr>
                    {
                        propsPermisos.permisos.map((permiso) => {
                            let id = `input_${propsPermisos.id}_${permiso.id}`;

                            return <div key={`key_permiso_${permiso.id}`} className="checkbox">
                                <input id={id} name={id} type="checkbox" value={'1'} />
                                <label htmlFor={id}>{permiso.nombre}</label>
                            </div>
                        }
                        )}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Breadcrumb icon={Briefcase} title={'Nuevo usuario'} parent={['USUARIOS']} />

            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body">
                        <form id='form_usuario' className="theme-form">

                            <div className='form-group row'>

                                <div className='col-sm-9 col-lg-10'>

                                </div>
                            </div>

                            <div className="form-group row">
                                <div className='col-12 col-md-6 mb-4'>
                                    <div className='input-field select'>
                                        <select className='form-select' data-label='empresa' name='input_EMPRESA' id='input_EMPRESA' required spellcheck="false">
                                            <option key={'0_empresa'} hidden value={''}>Seleccione</option>
                                            {
                                                catalogos.empresas.map(item =>
                                                    <option key={`key_option_empresas_${item.ID}`} value={item.ID}>{item.NOMBRE}</option>
                                                )
                                            }
                                        </select>
                                        <label className='col-form-label' htmlFor="input_EMPRESA"><i class="fa fa-briefcase" aria-hidden="true"></i> Empresa</label>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <div className='input-field'>
                                        <input name='input_NOMBRES' id='input_NOMBRES' type="text" data-label="nombres" required spellcheck="false" />
                                        <label className="col-form-label" htmlFor="input_NOMBRES"><span className="icon-icono_Cliente"></span> Nombres</label>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <div className='input-field'>
                                        <input name='input_APELLIDOS' id='input_APELLIDOS' type="text" data-label="apellidos" required spellcheck="false" />
                                        <label className="col-form-label" htmlFor="input_APELLIDOS"><span className="icon-icono_Cliente"></span> Apellidos</label>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <div className='input-field'>
                                        <input name='input_CORREO' id='input_CORREO' type="text" data-label="nombre de sucursal" required spellcheck="false" />
                                        <label className="col-form-label" htmlFor="input_CORREO"><i class="fa fa-envelope" aria-hidden="true"></i> Correo</label>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <div className='input-field'>
                                        <input name='input_TELEFONO' id='input_TELEFONO' type="text" data-label="nombre de sucursal" required spellcheck="false" />
                                        <label className="col-form-label" htmlFor="input_TELEFONO"><i class="fa fa-phone" aria-hidden="true"></i> Telefono</label>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 mb-4'>
                                    <div className='input-field'>
                                        <input name='input_CELULAR' id='input_CELULAR' type="text" data-label="nombre de sucursal" required spellcheck="false" />
                                        <label className="col-form-label" htmlFor="input_CELULAR"><i class="fa fa-phone" aria-hidden="true"></i> Celular</label>
                                    </div>
                                </div>
                                {/* <div className='col-12 col-md-6 mb-4'>
                                    <div className='input-field select'>
                                        <select className='form-select' data-label='tipo de usuario' name='input_TIPO' id='input_TIPO' required spellcheck="false">
                                            <option key={'0_tipo_usuario'} hidden value={''}>Seleccione</option>
                                            {
                                                catalogos.tipos.map(item =>
                                                    <option key={`key_option_tipos_${item.ID}`} value={item.ID}>{item.NOMBRE}</option>
                                                )
                                            }
                                        </select>
                                        <label className='col-form-label' htmlFor="input_TIPO"><span className="icon-icono_Cliente"></span> Tipo usuario</label>
                                    </div>
                                </div> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="container-fluid d-print-none">
                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <div className="page-header-left">
                                    <h3>Asignar permisos</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <form id='form_permisos' className="theme-form">
                            <div className='row'>
                                {
                                    catalogos.permisosSecciones.map(seccion => {
                                        return (
                                            <div key={`key_seccion_${seccion.id}`} className='col-12 col-md-6'>
                                                <ComponentePermisos
                                                    titulo={seccion.titulo}
                                                    id={seccion.id}
                                                    permisos={seccion.permisos}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='row mt-4'>
                                <div className='col-12 text-center'>
                                    <button className='btn-nuevo' type='button' onClick={enviarDatosFormulario}>
                                        <span className="text">Guardar</span>
                                        <div className="icon-container">
                                            <div className="icon icon--left">
                                            </div>
                                            <div className="icon icon--right">
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Default;