import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import { GetPermisos } from '../common/getPermisos'
// import Dropzone from '../common/dropzone';
// import SelectFecth from '../    common/select-fetch';
// import SelectSimple from '../common/select-simple';
// import { NumericFormat } from 'react-number-format'
// import TablaPrecios from '../common/tabla_estatica';

const Default = ({ esEditar }) => {

    const [formDirF, setFormDirF] = useState({})
    const [catalogos, setCatalogos] = useState({})
    const navigate = useNavigate()
    const params = useParams();

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_direccion_fiscal_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            console.log(response);
            setCatalogos((s) => ({ ...response.catalogos }))
            setFormDirF(esEditar ? response.datos : {})
        })

    }, [esEditar])

    const onSelectEstado = (item) => {
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: item
        }, (response) => {
            setCatalogos((s) => ({
                ...s,
                ctgDfCd: response.datos
            }))

            setFormDirF((s) => ({
                ...s,
                ID_EDO_DF: item.clave
            }))
        })
    }

    const onClickFaIn = () => {
        const form = Tools.getForm('formDirF')
        console.log(form);

        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        form.data.id = params.id
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_direccion_fiscal_nuevo' : 'rt_direccion_fiscal_editar',
            method: 'POST',
            body: form.data,
        }, (response) => {
            console.log(response)

            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                navigate('/direccion-fiscal')
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Briefcase} title={'Direcciones Fiscales'} parent={[{ titulo: 'direcciones fiscales', link: '/direccion-fiscal' }, { titulo: `${esEditar ? 'editar' : 'nueva'} dirección fiscal`, link: `/direccion-fiscal/${esEditar ? params.id : 'nuevo'}` }]} />
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formDirF' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">

                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/direccion-fiscal')
                                }}></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVA'} DIRECCIÓN FISCAL</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales de la direccion fiscal</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_alias" name="txt_alias"
                                                type="text"
                                                defaultValue={formDirF ? formDirF.ALIAS_DIF : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i className="fa fa-file" aria-hidden="true"></i> Alias<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_alias" style={{ display: 'none' }}><strong>Falta completar (Alias)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_rs" name="txt_rs"
                                                type="text"
                                                defaultValue={formDirF ? formDirF.RAZON_SOCIAL_DIF : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i className="fa fa-file" aria-hidden="true"></i> Razón Social<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_rs" style={{ display: 'none' }}><strong>Falta completar (Razón Social)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_rfc" name="txt_rfc"
                                                type="text"
                                                defaultValue={formDirF ? formDirF.RFC_DIF : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i className="fa fa-file" aria-hidden="true"></i> R.F.C<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_rfc" style={{ display: 'none' }}><strong>Falta completar (R.F.C)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select data-label="Regimen Fiscal"
                                                id="list_regimen_fiscal"
                                                name="list_regimen_fiscal"
                                                value={formDirF ? formDirF.REGIMEN_FISCAL_DIF : ''}
                                                datos={catalogos.ctgDfRegimenFiscal}
                                                onChange={(item) => setFormDirF(s => ({ ...s, REGIMEN_FISCAL_DIF: item.clave }))}
                                                required
                                                spellcheck="false"
                                            />
                                            <label className="form-label"><i className="fa fa-file" aria-hidden="true"></i> Regimen Fiscal<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios list_regimen_fiscal" style={{ display: 'none' }}><strong>Falta completar (Regimen Fiscal)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select data-label="Uso CFDI"
                                                id="list_uso_cfdi"
                                                name="list_uso_cfdi"
                                                value={formDirF ? formDirF.USO_CFDI_DIF : ''}
                                                datos={catalogos.ctgDfUsoCfi}
                                                onChange={(item) => setFormDirF(s => ({ ...s, USO_CFDI_DIF: item.clave }))}
                                                required
                                                spellcheck="false"
                                            />
                                            <label className="form-label"><i className="fa fa-file" aria-hidden="true"></i> Uso CFDI<span className="text-danger">*</span></label>
                                        </div>

                                        <span className="text-danger txtVacios list_uso_cfdi" style={{ display: 'none' }}><strong>Falta completar (Uso CFDI)</strong></span>
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input id='correo_rs' name='correo_rs'
                                                autoComplete='off'
                                                type="text"
                                                defaultValue={formDirF ? formDirF.CORREO_DIF : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label className="form-label"><i className="fa fa-envelope" aria-hidden="true"></i> Correo<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios correo_rs" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select data-label="Estado"
                                                id="list_estado"
                                                name="list_estado"
                                                value={formDirF ? formDirF.ID_CES_DIF : ''}
                                                datos={catalogos.ctgEdo}
                                                onChange={(datos) => {
                                                    onSelectEstado(datos.item)
                                                    setFormDirF((s) => ({
                                                        ...s,
                                                        ID_CES_DIF: datos.clave
                                                    }))
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label className="form-label"><i className="fa fa-map-marker" aria-hidden="true"></i> Estado<span className="text-danger">*</span></label>
                                        </div>


                                        <span className="text-danger txtVacios list_estado" style={{ display: 'none' }}><strong>Falta sleccionar (Estado)</strong></span>
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select data-label="Ciudad"
                                                id="list_ciudad"
                                                name="list_ciudad"
                                                value={formDirF ? formDirF.ID_CCD_DIF : ''}
                                                datos={catalogos.ctgDfCd}
                                                onChange={({ item }) => {
                                                    setFormDirF((s) => ({
                                                        ...s,
                                                        ID_CCD_DIF: item.clave
                                                    }))
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label className="form-label"><i className="fa fa-map-marker" aria-hidden="true"></i> Ciudad<span className="text-danger">*</span></label>
                                        </div>
                                        <div className="form-group">


                                            <span className="text-danger txtVacios list_ciudad" style={{ display: 'none' }}><strong>Falta sleccionar (Ciudad)</strong></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input id='txt_colonia_rs' name='txt_colonia_rs'
                                                autoComplete='off' type="text"
                                                defaultValue={formDirF ? formDirF.COLONIA_DIF : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label className="form-label"><i className="fa fa-map-marker" aria-hidden="true"></i> Colonia<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_colonia_rs" style={{ display: 'none' }}><strong>Falta completar (Código Postal)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input id='txt_calle_rs' name='txt_calle_rs'
                                                autoComplete='off'
                                                type="text"
                                                defaultValue={formDirF ? formDirF.CALLE_DIF : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label className="form-label"><i className="fa fa-map-marker" aria-hidden="true"></i> Calle<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_calle_rs" style={{ display: 'none' }}><strong>Falta completar (Calle)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input id="txt_cp" name='txt_cp'
                                                autoComplete='off'
                                                type="text"
                                                defaultValue={formDirF ? formDirF.CP_DIF : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label className="form-label"><i className="fa fa-hashtag" aria-hidden="true"></i> C.P<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (C.P)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input id='txt_num_ext_rs' name='txt_num_ext_rs'
                                                autoComplete='off'
                                                type="text"
                                                defaultValue={formDirF ? formDirF.NUM_EXT_DIF : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label className="form-label"><i className="fa fa-hashtag" aria-hidden="true"></i> Número. Ext<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_num_ext_rs" style={{ display: 'none' }}><strong>Falta completar (Número. Ext)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input id='txt_num_int_rs' name='txt_num_int_rs'
                                                autoComplete='off'
                                                type="text"
                                                defaultValue={formDirF ? formDirF.NUM_INT_DIF : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label className="form-label"><i className="fa fa-hashtag" aria-hidden="true"></i> Número. Int</label>
                                        </div>
                                        <span className="text-danger txtVacios txt_num_int_rs" style={{ display: 'none' }}><strong>Falta completar (Número. Int)</strong></span>
                                    </div>
                                    {/* <div className="col-sm-12 col-md-12 mb-3">
                                        <div className='input-field'>
                                            <textarea id="txt_comentarios" name='txt_comentarios' rows={5}
                                                autoComplete='off'
                                                defaultValue={formDirF.COMENTARIOS_DIF}
                                                required
                                                spellcheck="false"
                                            ></textarea>
                                            <label className="form-label"><i className="fa fa-comment" aria-hidden="true"></i> Comentarios<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (C.P)</strong></span>
                                    </div> */}
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            {(esEditar && (GetPermisos.find(permiso => [2046].includes(permiso)) ? true : false)) ||
                                                (!esEditar && (GetPermisos.find(permiso => [19].includes(permiso)) ? true : false)) ?
                                                <button className='btn-nuevo' type='button' onClick={onClickFaIn}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default