import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import { GetPermisos } from '../common/getPermisos'
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({ esEditar }) => {

    const [formFaIn, setFormFaIn] = useState({})
    const navigate = useNavigate()
    const params = useParams();

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_familias_insumos_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            setFormFaIn(esEditar ? response.result[0] : {})
        })
    }, [esEditar])

    const onClickFaIn = () => {
        const form = Tools.getForm('formFaIn')
        console.log(form);

        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        form.data.id = params.id
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_familias_insumos_nuevo' : 'rt_familias_insumos_editar',
            method: 'POST',
            body: form.data,
        }, (response) => {
            console.log(response)

            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                window.location.href = '/familias-insumos'
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Briefcase} title={'Familias insumos/articulos'} parent={[{ titulo: 'familias insumos/articulos', link: '/familias-insumos' }, { titulo: `${esEditar ? 'editar' : 'nueva'} familia`, link: `/familias-insumos/${esEditar ? params.id : 'nuevo'}` }]} />
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formFaIn' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">

                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/familias-insumos')
                                }}></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVA'} FAMILA DE INSUMOS/ARTICULOS</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales de la famila de insumo/articulo</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                defaultValue={formFaIn ? formFaIn.NOMBRE_FI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_titulo" name="txt_titulo"
                                                type="text"
                                                defaultValue={formFaIn ? formFaIn.TITULO_FI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Título<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (Título)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="tipo"
                                                id="clave_tipo"
                                                name="clave_tipo"
                                                value={formFaIn.TIPO_FI}
                                                datos={[{ clave: 1, label: 'Insumos' }, { clave: 2, label: 'Articulos' }]}
                                                onChange={(e) => { setFormFaIn(l => { return { ...l, TIPO_FI: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar tipo<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_abreviatura" style={{ display: 'none' }}><strong>Falta seleccionar (tipo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_abreviatura" name="txt_abreviatura"
                                                type="text"
                                                defaultValue={formFaIn ? formFaIn.ABREVIATURA_FI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Abreviatura<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_abreviatura" style={{ display: 'none' }}><strong>Falta completar (Abreviatura)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className='input-field'>
                                            <textarea autoComplete='off'
                                                id="txt_descripcion" name="txt_descripcion"
                                                type="text"
                                                defaultValue={formFaIn ? formFaIn.DESCRIPCION_FI : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Descripción<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_descripcion" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            {(esEditar && (GetPermisos.find(permiso => [2046].includes(permiso)) ? true : false)) ||
                                                (!esEditar && (GetPermisos.find(permiso => [19].includes(permiso)) ? true : false)) ?
                                                <button className='btn-nuevo' type='button' onClick={onClickFaIn}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default