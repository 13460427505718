import React, { useState, useEffect, useRef, Fragment } from 'react';
// import MySelect from '../common/select'
import Tools from '../../../js/tools'
import TableReact from '../../common/tablereact'
import {NumericFormat} from 'react-number-format'

const Default = (props) => {
    const childRef = useRef();
    const [loading, setLoading] = useState(true);
    const [datos, setDatos] = useState(props.datos? props.datos: [])

    const styleHeader = {
        overflowWrap: "break-word", 
        whiteSpace: 'unset', 
        fontSize: 11
    }

    const styleHead ={
        fontSize: 10
    }

    const headerTable = ([
        { Header: <b style={{...styleHead}}>CLIENTE</b>, accessor: 'CLIENTE', style: { textAlign: 'center', ...styleHeader}, width:'auto', show: true }, 
        { Header: <b style={{...styleHead}}>DIRECCIÓN</b>, accessor: 'ESTACION', style: { textAlign: 'center', ...styleHeader}, width:'auto', show: true }, 
        { Header: <b style={{...styleHead}}>FACTURAS POR PAGAR</b>, accessor: 'FACTURAS', style: { textAlign: 'center', ...styleHeader}, width:'auto', show: true }, 
        { Header: <b style={{...styleHead}}>SALDO</b>, accessor: 'TOTAL', style: { textAlign: 'center', ...styleHeader}, width:'auto', show: true, 
        cell: (cellInfo ) => { 
            return <Fragment>
                    <span style={{fontWeight: 800, color: 'rgba(0, 0, 0, 0.7)', fontSize: 12}}><NumericFormat thousandSeparator={true} 
                        displayType='text'
                        prefix={'$ '}
                        onValueChange={(values) => null}
                        value={Tools.toFixed2(cellInfo.original.TOTAL)}/></span>
            </Fragment> 
        } }, 
        // { Header: <b>SUBTOTAL</b>, accessor: 'COM_SUB_TOTAL_FAC', style: { textAlign: 'center', textTransform:'uppercase', ...styleHeader,}, width:'auto', editable:true, show: true },
        // { Header: <b style={{...styleHead}}>ESTATUS_PAGO</b>, accessor: 'DESCRIPCION_ESTATUS_PAGO', style: { textAlign: 'center', textTransform:'uppercase', ...styleHeader,}, width:'auto', editable:true, show: true, 
        // cell: (cellInfo ) => { 
        //     return <Fragment>
        //              <span className={`badge`} style={{backgroundColor: cellInfo.original.COLOR_ESTATUS_PAGO, fontSize: 14 }}>
        //             {cellInfo.original.DESCRIPCION_ESTATUS_PAGO}</span>
        //     </Fragment> 
        // } },
        
        // { Header: <b style={{...styleHead}}>DETALLE</b>, accessor: 'ID_DE', style: { textAlign: 'center', textTransform:'uppercase', ...styleHeader }, width:'auto', editable:true,  show: true,
        //     cell: (cellInfo ) => { 
        //     return <Fragment> <BtnVer onClick={() => {props.onClickVer(cellInfo.original)}} /></Fragment> }
        // },
    ])

    useEffect(() => {
        let isSubscribed = true
        Tools.toolFetch({ 
            ruta: 'rt_cuentas_por_pagar',
            method: 'POST',
        }, (response) => {
            if(isSubscribed){
                if(response.estado){
                    setDatos(response.resultado)
                }
                setLoading(false)
            }
        })
        return () => isSubscribed = false
    }, [])

    return (
    <div className="row">
        <div className="col-xl-12 col-sm-12">
            {/* <TableReact
                ref={childRef}
                myData={datos? datos: []}
                cabecera={headerTable}
                pageSize={10}
                paginacion={true}
                viewColumns={false}
                loading={loading}
                noDataText={'No hay empresas con cuentas por pagar.'}
                style={{fontFamily: 'Arial', fontSize: 12}}
                // buscador='Buscar Empresas'
            /> */}
        </div>
    </div>)
}

export default Default