import React, { Fragment } from 'react'

const Default = ({
    headers = [],
    rows = []
}) => {

    return <Fragment>
        <div className='table-responsive-sm'>
        {/* table-sticky */}
            <table className="table table-sm">
                <thead>
                    <tr>
                        {headers.map((h) => (
                            <td key={h.nombre} scope="col">{h.nombre}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.length?rows.map((r,p) => (
                        <tr key={'row'+p}>
                            {headers.map((h, i) => (
                                <td style={h.style} key={'col'+p+'-'+i}>
                                {h.tipo === 'html'? h.html(r, p)
                                : r[h.clave]}
                                </td>
                            ))}
                        </tr>
                    )): <tr><td colSpan={headers.length}>Sin registros</td></tr>}
                </tbody>
            </table>
        </div>
    </Fragment>
}

export default Default;