import React, { Fragment, useEffect, useState} from 'react';
import gpack from '../../../assets/images/paley.png';
import corpoe from '../../../assets/images/paley.png';
// import "react-datepicker/dist/react-datepicker.css";
// import Tools from '../../../js/tool'
import GraficaGeneral from '../../graficas/grafica_general'
import EmpresasDeben from './empresas_deben'
import CountUp from 'react-countup';
import { DollarSign } from 'react-feather';
import DatosIndicadores from '../../datos_cuenta/datos_indicadores'

const Default = (props) => {
    const [datosIniciales, setDatosIniciales] = useState(props.datosEmpresa? props.datosEmpresa: {})
    const [saldoTotal, setSaldoTotal] = useState(props.saldoTotal? props.saldoTotal: {})
    const [loadingInfo, setLoadingInfo] = useState(false)

    useEffect(() => {
        let isSubscribed = true
        if(isSubscribed){
            setDatosIniciales(props.datosEmpresa? props.datosEmpresa: {})
            setSaldoTotal(props.saldoTotal? props.saldoTotal: {})
            setLoadingInfo(props.datosEmpresa.CORREO? true: false)
        }
        return () => isSubscribed = false
    }, [props.datosEmpresa])


    const imgLogo = () => {
        let urlImg = JSON.parse(localStorage.getItem('UID')? localStorage.getItem('UID'): {})
        if(urlImg.EID === 1){
            return corpoe
        }else{
            return gpack
        }
    }

    return (
        <Fragment>
            {/* <DatePicker className="form-control digits" startDate={this.state.startDate} selected={this.state.startDate} onChange={() => null} /> */}
            <div className="tab-content active default " id="tab-1">
                <div className="row">
                    <div className="col-sm-12">
                        <div className='user-profile'>
                            <div className="card hovercard text-center">
                                <div style={{padding: 15}}><img className="pro" alt="" src={imgLogo()} data-intro="" style={{width: 350}} /></div>
                                {/* <div className="user-image " style={{paddingTop: 90}}>
                                    <div className="avatar ">
                                        <img className="pro" alt="" src={url ? url : objUsuario.urlImg} onError={onErrorImg} data-intro="This is Profile image" />
                                    </div>
                                    <div className="icon-wrapper">
                                        <i className="icofont icofont-pencil-alt-5" data-intro="Change Profile image here" >
                                            <input className="pencil" type="file"  />
                                        </i>
                                    </div>
                                </div> */}

                                <div className="info">
                                    <div className="row detail" data-intro="This is the your details">
                                        {loadingInfo? <Fragment>
                                            <div className="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="ttl-info text-left">
                                                            <h6><i className="fa fa-envelope mr-2"></i>Correo</h6><span>{datosIniciales.CORREO? datosIniciales.CORREO: ''}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="ttl-info text-left ttl-sm-mb-0">
                                                            <h6><i className="fa fa-calendar"></i> Fecha Registro</h6><span>{datosIniciales.FECHA_ALTA? datosIniciales.FECHA_ALTA: ''}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
                                                <div className="user-designation">
                                                    <div className="title"><a target="_blank" href="">{datosIniciales.RAZON_SOCIAL? datosIniciales.RAZON_SOCIAL: ''}</a></div>
                                                    <div className="desc mt-2">{datosIniciales.NOMBRE_COMERCIAL? datosIniciales.NOMBRE_COMERCIAL: ''}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="ttl-info text-left ttl-xs-mt">
                                                            <h6><i className="fa fa-book"></i> Dirección</h6><span>{datosIniciales.CALLE_NUMERO}, N Ext. {datosIniciales.NUM_EXT} {datosIniciales.NUM_INT? `N Int. ${datosIniciales.NUM_INT}`: ','} {datosIniciales.COLONIA}, 
                                                            {datosIniciales.CP}, {datosIniciales.CIUDAD_ALCALDIA}, {datosIniciales.DESCRIPCION_ESTADO}</span>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <div className="ttl-info text-left ttl-sm-mb-0">
                                                            <h6><i className="fa fa-bank"></i> Datos Fiscales</h6><span>{datosIniciales.RAZON_SOCIAL}, {datosIniciales.RFC}, {datosIniciales.REGIMEN_FISCAL}</span>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Fragment>:<div className="col-sm-12">
                                            <div className="loader-box justify-content-center d-flex" style={{height: 60}}>
                                                <span className="rotate dashed">
                                                </span>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="row">
                                        <div className="col-xl-12 col-sm-12">
                                            <h5 className="mb-0">Ventas</h5>
                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                        </div>
                                    </div>
                                </div>
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={45} />
                                    </h1>
+                                </div>
                            </div>
                            <div className="card-footer project-footer">
                                <div className="media">
                                    <div className="select2-drpdwn-project select-options">
                                        <h6 className="mb-0"> Valor: 
                                            <span className="counter ml-1">
                                                <Countup end={14} />
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <h5 className="mb-0">Ventas Canceladas</h5>
                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                        </div>
                                    </div>
                                </div>
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={45} />
                                    </h1>
                                </div>
                            </div>
                            <div className="card-footer project-footer">
                                <div className="media">
                                    <div className="select2-drpdwn-project select-options">
                                        <h6 className="mb-0"> Valor:
                                            <span className="counter ml-1">
                                                <Countup end={14} />
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="media">
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-6">
                                            <h5 className="mb-0">Solicitudes</h5>
                                        </div>
                                        <div className="col-xl-6 col-sm-6">
                                        </div>
                                    </div>
                                </div>
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={45} /></h1>
                                </div>
                            </div>
                            <div className="card-footer project-footer">
                                <div className="media">
                                    <div className="select2-drpdwn-project select-options">
                                        <h6 className="mb-0"> Partidas: 
                                            <span className="counter ml-1">
                                                <Countup end={14} />
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-xl-12 xl-100">
                        <div className="row">
                            
                            <div className="col-md-3">
                                <div className="card b-l-primary">
                                    <div className="card-body">
                                        <div className="media feather-main">
                                            <div className="feather-icon-block bg-danger">
                                                <Truck />
                                            </div>
                                            <div className="media-body align-self-center">
                                                <span style={{fontSize: 14}}><strong>SOLICITUDES CANCELADAS</strong></span>
                                                <h4><CountUp className="counter" end={datosSolicitud.SOLICITUDES_CANCELADAS? datosSolicitud.SOLICITUDES_CANCELADAS: 0}/></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="card b-l-primary">
                                    <div className="card-body">
                                        <div className="media feather-main">
                                            <div className="feather-icon-block bg-success">
                                                <Truck />
                                            </div>
                                            <div className="media-body align-self-center">
                                                <span style={{fontSize: 14}}><strong>SOLICITUDES CONCLUIDAS</strong></span>
                                                <h4><CountUp className="counter" end={datosSolicitud.SOLICITUDES_CONCLUIDAS? datosSolicitud.SOLICITUDES_CONCLUIDAS: 0} /></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-sm-6">
                        <div className="card">
                            <div className="card-header b-l-primary">
                                <h5>EMPRESAS CON CUENTAS POR PAGAR</h5>
                            </div>
                            <div className='row mr-3 mt-2'>
                                <div className="col-xl-12 col-sm-12 text-right">
                                    
                                    <h6><strong>Saldo Total:</strong><span className="counter">
                                        <DollarSign size={14}/>
                                        <CountUp className="counter" decimals={2} separator={','} end={saldoTotal.SALDO_TOTAL? saldoTotal.SALDO_TOTAL: 0} />
                                    </span></h6>
                                </div>
                            </div>
                            <EmpresasDeben />
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <DatosIndicadores 
                            ruta={'rt_indicadores_empresa'}
                            body={{ ID_EMPRESA: datosIniciales.ID_EMPRESA }}
                            // tipo={true}
                        />
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header b-l-primary">
                                <h5>GRAFICA LINEAL DE VENTAS</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12 col-sm-12 p-3">
                                        <GraficaGeneral ruta={'rt_next_grafica'} body={{
                                            NEXT: 0, 
                                            TIPO_GRAFICA: 0
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-sm-6">
                        <div className="card">
                            <div className="card-header b-l-primary">
                                <h5>EMPRESAS CON CUENTAS POR PAGAR</h5>
                            </div>
                            <EmpresasDeben />

                        </div>
                    </div> */}
                    {/* <div className="col-xl-6">
                        <div className="card">
                            <div className="card-header b-l-primary">
                                <h5>Sales Statistics</h5>
                            </div>
                            <div className="card-body">
                                <div className="show-value-top d-flex pull-right">
                                    <div className="value-left d-inline-block">
                                        <div className="circle-graph bg-primary d-inline-block m-r-5"></div><span>Total Earning</span>
                                    </div>
                                    <div className="value-right d-inline-block">
                                        <div className="circle-graph d-inline-block bg-secondary m-r-5"></div><span>Total Tax</span>
                                    </div>
                                </div>
                                <div className="chart-block sales-block">
                                </div>
                                <div className="card-footer project-footer">
                                    <h6 className="mb-0">Completed: 
                                        <span className="counter ml-1">
                                            <Countup end={14} />
                                        </span>
                                    </h6>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 xl-100 project-col">
                        <div className="card">
                            <div className="card-header">
                                <h5>Budget Distribution</h5>
                            </div>
                            <div className="card-body chart-block">
                                <div className="flot-chart-container budget-chart">
                                    <div className="flot-chart-placeholder" id="default-pie-flot-chart">
                                        <Pie data={pieChartData} options={pieChartOption} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </Fragment>
    );
}

export default Default;