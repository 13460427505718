import React, { Fragment, useEffect, useState} from 'react';
// import DatePicker from "react-datepicker";
// import MySelect from '../../common/select'
import { DollarSign, Hash, Droplet, Package } from 'react-feather';
import CountUp from 'react-countup';
import "react-datepicker/dist/react-datepicker.css";
// import DateTimePicker from 'react-datetime-picker';
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Tools from '../../js/tools'

const Default = (props) => {
    const [loading, setLoading] = useState(true)
    const [dates, setOnChangeDate] = useState([new Date(), new Date()]);
    const [datosConceptos, setDatosConceptos] = useState([]);
    const [datos, setDatos] = useState({ })

    const onChangeDate = (_dates) => {
        setOnChangeDate(_dates)
        if(!_dates){
            console.log(_dates)
            return
        }

        let body = props.body? props.body: {}
        body['FECHA1'] = _dates[0]
        _dates[1].setDate(_dates[1].getDate() -1)
        body['FECHA2'] =   _dates[1]
        setLoading(true)

        Tools.toolFetch({ 
            ruta: props.ruta? props.ruta: '',
            method: 'POST',
            body: body,
        }, (response) => {
            if(response.estado){
                setDatos(response.resultado[0][0])
                setDatosConceptos(response.resultado[1])
                setLoading(false)
            }
        })

        // fetch(`/${props.ruta? props.ruta: ''}`, {
        //     body: JSON.stringify(body),
        //     method: 'POST', 
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        // })
        // .then((response) => response.json())
        // .then((response) => {
        //     if(response.estado){
        //         setDatos(response.resultado[0][0])
        //         setDatosConceptos(response.resultado[1])
        //         setLoading(false)
        //     }
        // })
    }

    useEffect(() => {
        let isSubscribed = true

        Tools.toolFetch({ 
            ruta: props.ruta? props.ruta: '',
            method: 'POST',
            body: props.body,
        }, (response) => {
            if(isSubscribed){
                if(response.estado){
                    setDatos(response.resultado[0][0])
                    setDatosConceptos(response.resultado[1])
                }
                setLoading(false)
            }
        })

        // fetch(`/${props.ruta? props.ruta: ''}`, {
        //     body: JSON.stringify(props.body),
        //     method: 'POST', 
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        // })
        // .then((response) => response.json())
        // .then((response) => {
        //     if(isSubscribed){
        //         if(response.estado){
        //             setDatos(response.resultado[0][0])
        //             setDatosConceptos(response.resultado[1])
        //         }
        //         setLoading(false)
        //     }
        // })
        return () => isSubscribed = false
    }, [props.body])

    return (
<Fragment>
    <div className='row'>
        <div className={`${props.tipo? 'col-xl-6 col-lg-6 col-sm-12 col-md-12': 'col-xl-12 col-lg-12 col-sm-6 col-md-6'}`}>
            <div className="card b-l-primary">
                <div className="card-body date-picker m-0 p-0 pl-3">
                    <div className='row'>
                        <div className="col-xl-5 col-sm-5">
                            <label className="col-form-label">Ver indicadores de (--/--/--) a (--/--/--)</label>
                             <div className="form-group">
                                {/* <DateRangePicker
                                    className='form-control digits'
                                    onChange={onChangeDate}
                                    value={dates}
                                /> */}
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={`${props.tipo? 'col-xl-3 col-lg-3 col-sm-12 col-md-12': 'col-xl-6 col-lg-6 col-sm-12 col-md-12'}`}>
            <div className="card b-l-primary">
                <div className="card-body m-0 p-4">
                    <div className="media chart-university">
                        <div className="media-body">
                            {loading ? <Fragment>
                                <div className="loader-box justify-content-center d-flex" style={{height: 60}}>
                                    <span className="rotate dashed">
                                    </span>
                                </div></Fragment>:<Fragment>
                                <h4 className="mb-0">
                                <DollarSign size={18} />
                                <span className="counter">
                                    <CountUp className="counter" decimals={2} separator={','} end={datos.VENTAS_TOTALES? datos.VENTAS_TOTALES: 0} />
                                </span></h4>
                                <div className='row'>
                                    <div className="col-sm-6 text-left">
                                        <p style={{fontSize: 15}}>Ventas Totales</p>
                                    </div>
                                    <div className="col-sm-6 text-right">
                                        <Hash size={13}/>
                                        <CountUp 
                                        className="counter" 
                                        end={datos.NUMERO_VENTAS? datos.NUMERO_VENTAS: 0} 
                                        separator={','}/>
                                    </div>
                                </div>
                            </Fragment>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={`${props.tipo? 'col-xl-3 col-lg-3 col-sm-12 col-md-12': 'col-xl-6 col-lg-6 col-sm-12 col-md-12'}`}>
            <div className="card b-l-primary">
                <div className="card-body m-0 p-4">
                    <div className="media chart-university">
                        <div className="media-body">
                        {loading ? <Fragment>
                                <div className="loader-box justify-content-center d-flex" style={{height: 60}}>
                                    <span className="rotate dashed">
                                    </span>
                                </div></Fragment>:<Fragment>
                                <h4 className="mb-0 text-success">
                                <DollarSign size={18}/>
                                    <span>
                                        <CountUp className="counter" decimals={2} separator={','} end={datos.UTILIDAD_FACTURA? datos.UTILIDAD_FACTURA: 0} />
                                    </span>
                                </h4>
                                <p style={{fontSize: 15}}>Utilidad Total</p>
                            </Fragment>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {datosConceptos.length > 0? 
        datosConceptos.map((v, i) => {
            return (<div key={i} className={`${props.tipo? 'col-xl-3 col-lg-3 col-sm-12 col-md-12': 'col-xl-6 col-lg-6 col-sm-12 col-md-12'}`}>
                
                <div className="card b-l-primary">
                    <div className="card-body m-0 p-2">
                        <div className="media feather-main d-flex align-items-center justify-content-center">
                        {loading ?<Fragment>
                                <div className="loader-box justify-content-center d-flex" style={{height: 60}}>
                                    <span className="rotate dashed">
                                    </span>
                                </div>
                            </Fragment> : <Fragment> 
                            <div className="feather-icon-block" style={{backgroundColor: v.COLOR_PRODUCTO, color: '#262121'}}>
                                <Package />
                            </div>
                            <div className="media-body align-self-center">
                                <span style={{fontSize: 14}}><strong>{v.DESCRIPCION_PRODUCTO}</strong></span>
                                <div className='row'>
                                    <div className="col-sm-12 text-left">
                                        <h6>
                                        <Hash size={15}/>
                                        <CountUp 
                                        className="counter" 
                                        end={v.TOTAL_VENTAS} 
                                        separator={','}/></h6>
                                    </div>
                                    <div className="col-sm-12 text-right text-success">
                                        <h6>
                                        <DollarSign size={15}/>
                                        <CountUp 
                                        className="counter" 
                                        end={v.UTILIDAD_TOTAL} 
                                        decimals={2}  
                                        separator={','}/></h6>
                                    </div>
                                    <div className="col-sm-12 text-right">
                                        <h6>
                                        <DollarSign size={15}/>
                                        <CountUp 
                                        className="counter" 
                                        end={v.IMPORTE_TOTAL} 
                                        decimals={2}  
                                        separator={','}/></h6>
                                    </div>
                                </div>
                            </div>
                        </Fragment>}
                        </div>
                    </div>
                </div>

            </div>)
        })
        : <Fragment>
        <div className={`${props.tipo? 'col-xl-3 col-lg-3 col-sm-12 col-md-12': 'col-xl-6 col-lg-6 col-sm-12 col-md-12'}`}>
            <div className="card b-l-primary">
                <div className="card-body m-0 p-2">
                    <div className="media feather-main d-flex align-items-center justify-content-center">
                        <div className="loader-box justify-content-center d-flex" style={{height: 60}}>
                        {loading? <span className="rotate dashed">
                            </span>: 'Sin producto que mostrar'}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>}
            </div>
</Fragment>
    )
}

export default Default