
import React, { Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumbv2'
import Table from '../common/tabla'
import { Briefcase } from 'react-feather';
import { useNavigate } from 'react-router-dom'
import { GetPermisos } from '../common/getPermisos';
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import notie from 'notie'
import Tools from '../../js/tools'

const Default = () => {
    const navigate = useNavigate()
    const [esRefresh, setEsRefresh] = useState(true)

    const onClickEliminar = (d) => {
        notie.confirm({
            text: `Se eliminara <strong>${d.NOMBRE_EM}</strong> ¿estas de acuerdo?`,
            submitText: 'Si, eliminar',
            cancelText: 'No, cancelar',
            submitCallback: function (value) {
                Tools.toolFetch({
                    ruta: 'rt_empresas_eliminar',
                    method: 'POST',
                    body: { id: d.ID_EM },
                }, (response) => {
                    if (response.estatus === 'ERROR') {
                        return notie.alert({
                            type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                            text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                            // stay: Boolean, // optional, default = false
                            time: 2, // optional, default = 3, minimum = 1,
                            position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                        })
                    }

                    setEsRefresh((s) => !s)
                })
            },
            cancelCallback: function (value) {
            },
        })
    }

    return <Fragment>
        <Breadcrumb icon={Briefcase} title={'Empresa'} parent={[{ titulo: 'empresa', link: '/empresa' }]} />
        <div className="card">
            <div className="col-xl-12 col-md-12">
                <div className="card-header d-flex justify-content-center p-3">
                    <h4><strong>EMPRESAS PRINCIPALES</strong></h4>
                </div>
                <div className="mt-3 px-4">
                    <Table
                        noRutaDinamica={true}
                        cargando={false}
                        htmlBtnPag={
                            <div>
                                {(GetPermisos.find(permiso => [2038].includes(permiso)) ? true : false) ?
                                    <button
                                        className="btn-nuevo"
                                        type="button"
                                        onClick={() => {
                                            navigate('/empresa/nueva')
                                        }}
                                    >
                                        <span className="text">Nuevo</span>
                                        <div className="icon-container">
                                            <div className="icon icon--left">
                                            </div>
                                            <div className="icon icon--right">
                                            </div>
                                        </div>
                                    </button>
                                    : ''
                                }
                            </div>
                        }
                        headers={[
                            {
                                nombre: 'EMPRESA', tipo: 'html', texto: 'NOMBRE_EM', html: (d) => {
                                    return <div>{d.NOMBRE_EM}</div>
                                }
                            },
                            {
                                nombre: 'RESPONSABLE', tipo: 'html', texto: 'NOMBRES_CONTACTO_EM', html: (d) => {
                                    return <div>{d.NOMBRES_CONTACTO_EM} {d.APELLIDOS_CONTACTO_EM}</div>
                                }
                            },
                            {
                                nombre: 'CONTACTO', tipo: 'html', texto: 'NOMBRES_CONTACTO_EM', html: (d) => {
                                    return <div>
                                        <strong>Celular:</strong> {d.CELULAR_CONTACTO_EM}<br />
                                        <strong>Teléfono:</strong> {d.TELEFONO_CONTACTO_EM}
                                    </div>
                                }
                            },
                            {
                                nombre: 'DETALLE', tipo: 'html', texto: 'Detalle',
                                html: (d) => {
                                    return <div>
                                        <BtnVer
                                            onClick={() => {
                                                navigate(`/empresa/${d.ID_EM}`)
                                            }}
                                        />
                                        {(GetPermisos.find(permiso => [2039].includes(permiso)) ? true : false) ?
                                            <BtnEliminar
                                                onClick={() => onClickEliminar(d)}
                                            />
                                            : ''
                                        }
                                    </div>
                                }
                            }
                        ]}
                        totalField={'NR'}
                        path={'/rt_empresas'}
                        refresh={esRefresh}
                        body={{}}
                        rowsPerPage={20}
                        paginadorTop={true}
                    >
                    </Table>
                </div>
            </div>
        </div>
    </Fragment>
}

export default Default