import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({
    esEditar
}) => {
    const [catalogos, setCatalogos] = useState({})
    const [selectSuc, setSelectSuc] = useState([])
    const [preciosSuc, setPreciosSuc] = useState([])
    const [prodServ, setProdServ] = useState(null)
    const [selectPrecios, setSelectPrecios] = useState([])
    const [headers, setHeaders] = useState([])
    const [zonas, setZonas] = useState([])
    const [tipoServ, setTipoServ] = useState({})
    const [rows, setRows] = useState([])
    const [refreshPrimeraCarga, setRefreshPrimeraCarga] = useState(true)
    const [img, setImg] = useState({})
    const navigate = useNavigate()
    const params = useParams();

    const uploadImg = (img, datos) => {
        let arrImg = []

        if (img.imgPortada) {
            arrImg = [...arrImg, { img: img.imgPortada, nombre: 1 }]
        }

        if (img.imgDetalle) {
            arrImg.push({ img: img.imgDetalle, nombre: 2 })
        }
        let length = arrImg.length

        let arrRes = [], inputs = {
            ruta: `http://localhost:3010/rt_upload_img_prod_serv`,
            arr: arrImg,
            index: 0,
            id: datos.ID_PS
        }

        let callback = (datos) => {
            if (datos.index <= length - 1) {
                arrRes.push(datos)
                Tools.subirImg({
                    ...inputs,
                    arr: arrImg,
                    index: datos.index
                }, callback)
            } else {
                if (!esEditar) {
                    setTimeout(() => {
                        navigate('/servicios')
                    }, 3000)
                }

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'Registro realizado exitosamente.',
                    stay: false, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        }

        Tools.subirImg(inputs, callback)
    }

    const guardar = () => {
        const _prodServ = Tools.getForm('formProdServ', { claveEtiquetas: '.txtVacios', ignore: [] })

        if (_prodServ.isEmtyCount || (!img.imgPortada && !esEditar)) {
            let mensajeP = ''
            if (!img.imgPortada && !esEditar) {
                mensajeP = `${_prodServ.isEmtyCount ? `, ` : ''}Portada`
                setImg((s) => ({ ...s, statusPortada: 'removed', imgPortada: null }))
            }
            let mensaje = `${_prodServ.isEmtyCount ? _prodServ.isEmtyString : ''}${mensajeP}`
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (tipoServ.clave == 1) {
            if (!(selectPrecios.length === ((headers.length - 1) * (zonas.length - 1)))) {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Falta completar la tabla de precios`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        }

        Tools.toolFetch({
            ruta: esEditar ? 'rt_prod_serv_editar' : 'rt_prod_serv_nuevo',
            method: 'POST',
            body: {
                selectPrecios,
                ...(prodServ ? { id_ps: prodServ.ID_PS } : {}),
                ..._prodServ.data,
                selectSuc
            },
        }, (response) => {
            if (response.estatus === 'EXISTE') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Producto <strong>${_prodServ.data.txt_nombre}</strong> existente intente con otro nombre diferente`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            if (!esEditar) {
                uploadImg(img, response.datos)
            } else {
                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: 'Registro realizado exitosamente.',
                    stay: false, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        })
    }

    const guardarPrecios = () => {
        const _prodServ = Tools.getForm('formProdServ', {
            claveEtiquetas: '.txtVacios',
            ignore: ['list_grupo', 'list_prod_serv', 'list_suc', 'list_tipo', 'txt_desc', 'txt_nombre']
        })

        if (_prodServ.isEmtyCount) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_prodServ.isEmtyString})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (tipoServ.clave == 1) {
            if (!(selectPrecios.length === ((headers.length - 1) * (zonas.length - 1)))) {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Falta completar la tabla de precios`,
                    // stay: Boolean, // optional, default = false
                    time: 3, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }
        }

        Tools.toolFetch({
            ruta: 'rt_prod_serv_precios_suc',
            method: 'POST',
            body: {
                selectPrecios,
                list_tipo: tipoServ.clave,
                id_ps: prodServ.ID_PS,
                ..._prodServ.data
            },
        }, (response) => {
            setRefreshPrimeraCarga((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Precios actualizados correctamente.',
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardarSucursales = () => {
        const _prodServ = Tools.getForm('formProdServ', {
            claveEtiquetas: '.txtVacios',
            ignore: ['list_grupo', 'list_prod_serv', 'list_suc', 'list_tipo', 'txt_desc', 'txt_nombre']
        })

        if (_prodServ.isEmtyCount) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${_prodServ.isEmtyString})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (!selectSuc.length) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar seleccionar (Sucursales)`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        Tools.toolFetch({
            ruta: 'rt_prod_serv_suc',
            method: 'POST',
            body: {
                selectSuc,
                list_tipo: tipoServ.clave,
                id_ps: prodServ.ID_PS,
                ..._prodServ.data
            },
        }, (response) => {
            setRefreshPrimeraCarga((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Se actualizaron correctamente las sucursales.',
                stay: false, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const guardarImg = (tipoImg) => {
        if (tipoImg === 1 && !img.imgPortada && esEditar) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (Portada)`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (tipoImg === 2 && !img.imgDetalle && esEditar) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (Portada)`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (tipoImg === 1) {
            uploadImg({ imgPortada: img.imgPortada }, prodServ)
        } else {
            uploadImg({ imgDetalle: img.imgDetalle }, prodServ)
        }
    }

    const btnAgregarProdServ = () => <div className="col-md-12 mb-3">
        <div className="justify-content-end d-flex">
            <button
                className="btn btn-primary btn-sm d-flex align-items-center"
                type="button"
                onClick={(e) => guardar()}
            >Guardar Producto Servicio</button>
        </div>
    </div>

    const renderHeader = (zonas) => {
        setHeaders([
            {
                nombre: 'PESO',
                clave: 'NOMBRE_PV',
            },
            ...zonas.map((v) => ({
                tipo: 'html',
                nombre: 'Zona ' + v.NOMBRE_CZ,
                clave: 'ID_PV',
                style: { padding: 2 },
                html: (d) => {
                    let selectItem = selectPrecios.find((f) => f.zona === v.ID_CZ && f.pesoVol === d.ID_PV)
                    // console.log(selectItem, selectPrecios)
                    return <NumericFormat
                        className="form-control text-end"
                        thousandSeparator=","
                        // name="txtPrecio"}
                        placeholder='0.00'
                        autoComplete='off'
                        value={selectItem ? selectItem.precio : 0}
                        onValueChange={(e) => {
                            let item = { precio: e.floatValue, pesoVol: d.ID_PV, zona: v.ID_CZ }

                            if (selectPrecios.find((f) => f.pesoVol === item.pesoVol && f.zona === item.zona)) {
                                setSelectPrecios((s) => s.map((g) => g.pesoVol === item.pesoVol && g.zona === item.zona ? ({ id: g.id, ...item }) : g))
                            } else {
                                setSelectPrecios((s) => ([...s, item]))
                            }
                        }}
                    />
                }
            }))
        ])
    }

    useEffect(() => {
        renderHeader(zonas)
    }, [selectPrecios])

    useEffect(() => {
        let isSubscribed = true

        if (esEditar) {
            return Promise.all([
                fetch('/rt_prod_serv_detalle', {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify({ id_ps: params.id })
                }),
                fetch('/rt_ctg_gpo', {
                    method: 'POST',
                    headers: { "Content-Type": 'application/json' },
                    body: JSON.stringify({})
                }),
            ])
                .then(async (response) => {
                    let detalle = await response[0].json()
                    let ctgGpo = await response[1].json()

                    // Gpo
                    let pesoVol = ctgGpo.datos.pesoVol
                    let zonas = ctgGpo.datos.zonas

                    if (!isSubscribed) {
                        return
                    }

                    renderHeader(zonas)
                    setZonas(zonas)
                    setRows(pesoVol)

                    let datos = ctgGpo.datos

                    setCatalogos((s) => ({
                        ...s,
                        ...datos
                    }))

                    // DETALLE
                    setTipoServ({ clave: detalle.datos.prodServ.TIPO_PS })
                    setSelectPrecios(detalle.datos.precios.map((v) => ({ id: v.ID_PS_PC, precio: v.PRECIO_PC, pesoVol: v.ID_PV_PC, zona: v.ID_CZ_PC })))
                    setProdServ(detalle.datos.prodServ)
                    setPreciosSuc(detalle.datos.preciosSuc)
                    setSelectSuc(detalle.datos.preciosSuc.map((v) => ({ value: v.ID_SUC_PRE, label: v.NOMBRE_SUC, precio: v.PRECIO_PRE })))
                })
        }

        Tools.toolFetch({
            ruta: 'rt_ctg_gpo',
            method: 'POST',
            body: {},
        }, (response) => {
            if (isSubscribed) {
                if (response.estatus === 'ERROR') {
                    return navigate('/clientes')
                }

                let pesoVol = response.datos.pesoVol
                let zonas = response.datos.zonas

                renderHeader(zonas)
                setZonas(zonas)
                setRows(pesoVol)

                let datos = response.datos

                setCatalogos((s) => ({
                    ...s,
                    ...datos
                }))
            }
        })

        return () => isSubscribed = false
    }, [refreshPrimeraCarga])

    return (
        <Fragment>
            <Breadcrumb icon={Briefcase} title={'Producto Servicios'} parent={['servicios']} />
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formProdServ' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-danger btn-sm" type="button"
                                    onClick={(e) => {
                                        // navigate('/servicios')
                                        window.location.href = '/servicios'
                                    }} >Regresar</button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVO'} PROVEEDOR</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales del producto servicio</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Tipo<span className="text-danger">*</span></label>
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="Tipo"
                                            placeholder="Seleccionar tipo"
                                            id="list_tipo"
                                            name="list_tipo"
                                            value={prodServ ? prodServ.TIPO_PS : ''}
                                            datos={catalogos.ctgTipos}
                                            onChange={(e) => {
                                                setTipoServ(e.item)
                                            }}
                                        />
                                        <span className="text-danger txtVacios list_tipo" style={{ display: 'none' }}><strong>Falta completar (Tipo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationTooltip02">Nombre<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"></div>
                                            <input className="form-control"
                                                autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                placeholder="Nombre"
                                                defaultValue={prodServ ? prodServ.NOMBRE_PS : ''}
                                            />
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Clave Producto Servicio<span className="text-danger">*</span></label>
                                        <SelectFecth
                                            id={'list_prod_serv'}
                                            name={'list_prod_serv'}
                                            ruta='rt_ctg_prod_serv'
                                            label={'Producto Servicio'}
                                            clave_cps={prodServ ? prodServ.CLAVE_CPS : ''}
                                            value={prodServ ? { value: prodServ.CLAVE_CPS, label: `${prodServ.CLAVE_CPS} ${prodServ.PROD_SERV_TEXT}` } : null}
                                        />
                                        <span className="text-danger txtVacios list_prod_serv" style={{ display: 'none' }}><strong>Falta completar (Clave Producto Servicio)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Grupo<span className="text-danger">*</span></label>
                                        <Select
                                            className="form-control form-control-md border border-dark"
                                            data-label="Grupo"
                                            placeholder="Seleccionar Grupo"
                                            id="list_grupo"
                                            name="list_grupo"
                                            value={prodServ ? prodServ.ID_GPO_PS : ''}
                                            datos={catalogos.ctgGpo}
                                            onChange={() => null}
                                        />
                                        <span className="text-danger txtVacios list_grupo" style={{ display: 'none' }}><strong>Falta completar (Grupo)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className="form-label">Descripción<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <textarea style={{ width: '100%' }} rows={5} id='txt_desc' name='txt_desc' placeholder='Descripción' defaultValue={prodServ ? prodServ.DESCRIPCION_PS : ''}></textarea>
                                        </div>
                                        <span className="text-danger txtVacios txt_desc" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>
                                    {esEditar ? btnAgregarProdServ() : ''}
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Imagenes que seran mostradas en PTV y Sitio</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Portada<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"></div>
                                            <Dropzone
                                                initialFiles={prodServ ? prodServ.IMG_PORTADA_URL : null}
                                                fileName={prodServ ? prodServ.IMG_PORTADA_PS : ''}
                                                onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                            />
                                        </div>
                                        <span className="text-danger" style={{ display: img.statusPortada === 'removed' ? '' : 'none' }}><strong>Falta completar (Portada)</strong></span>
                                        {esEditar ?
                                            <div className="justify-content-end d-flex mt-2">
                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="button"
                                                    onClick={(e) => guardarImg(1)} >Guardar Portada</button>
                                            </div>
                                            : ''}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label">Detalle (Opcional)</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"></div>
                                            <Dropzone
                                                initialFiles={prodServ ? prodServ.IMG_DETALLE_URL : null}
                                                fileName={prodServ ? prodServ.IMG_DETALLE_PS : ''}
                                                onImg={(datos) => { setImg((s) => ({ ...s, statusDatelle: datos.status, imgDetalle: datos.file })) }}
                                            />
                                        </div>
                                        {esEditar ?
                                            <div className="justify-content-end d-flex mt-2">
                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="button"
                                                    onClick={(e) => guardarImg(2)} >Guardar Detalle</button>
                                            </div>
                                            : ''}
                                    </div>
                                    {/* {tipoServ.clave == 2? */}
                                    <Fragment>
                                        <div className="col-md-12 mb-3">
                                            <h6><strong>Precios por sucursal</strong></h6>
                                            <hr />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Selecciona sucursales<span className="text-danger">*</span></label>
                                            {/* {preciosSuc.length?
                                            <SelectSimple
                                                id={'list_suc'}
                                                name={'list_suc'}
                                                isMulti={true}
                                                options={catalogos.ctgSuc}
                                                value={preciosSuc.map((v) => ({label: v.NOMBRE_SUC, value: v.ID_SUC_PRE}))}
                                                onChange={(datos) => {
                                                    setSelectSuc(datos)
                                                }}
                                            />
                                            : */}
                                            {esEditar ?
                                                <SelectSimple
                                                    id={'list_suc'}
                                                    name={'list_suc'}
                                                    isMulti={true}
                                                    options={catalogos.ctgSuc}
                                                    // value={preciosSuc.map((v) => ({label: v.NOMBRE_SUC, value: v.ID_SUC_PRE}))}
                                                    value={selectSuc}
                                                    onChange={(datos) => {
                                                        console.log(datos)
                                                        setSelectSuc(datos)
                                                    }}
                                                />
                                                : ''}
                                            <span className="text-danger txtVacios txt_desc" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                        </div>
                                        {esEditar && tipoServ.clave == 1 ?
                                            <div className="col-md-12 mb-3">
                                                <div className="justify-content-end d-flex">
                                                    <button
                                                        className="btn btn-primary btn-sm d-flex align-items-center"
                                                        type="button"
                                                        onClick={(e) => guardarSucursales()}
                                                    >Guardar Sucursales</button>
                                                </div>
                                            </div>
                                            : ''}
                                        {tipoServ.clave == 2 ? selectSuc.map((v, i) =>
                                            <Fragment key={v.label + i + v.value}>
                                                <div className="col-md-4 mb-3">
                                                    <label htmlFor="validationTooltip02">{v.label}<span className="text-danger">*</span></label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend"></div>
                                                        <NumericFormat
                                                            className="form-control text-end"
                                                            id={`txt_suc${v.value}`} name={`txt_suc${v.value}`}
                                                            placeholder='0.00'
                                                            autoComplete='off'
                                                            data-label={`${v.label}`}
                                                            defaultValue={v.precio}
                                                        />
                                                    </div>
                                                    <span className={`text-danger txtVacios txt_suc${v.value}`} style={{ display: 'none' }}><strong>Falta completar Precio ({v.label})</strong></span>
                                                </div>
                                            </Fragment>
                                        ) : ''}
                                    </Fragment>
                                    {/* :''} */}
                                    {tipoServ.clave == 1 ?
                                        <div className="col-md-12 mb-3">
                                            <h6><strong>Precios por zonas</strong></h6>
                                            <hr />
                                            <TablaPrecios
                                                headers={headers}
                                                rows={rows}
                                            />
                                        </div> : ''}
                                    {!esEditar ? btnAgregarProdServ() : ''}
                                    {esEditar ?
                                        <div className="col-md-12 mb-3">
                                            <div className="justify-content-end d-flex">
                                                <button
                                                    className="btn btn-primary btn-sm d-flex align-items-center"
                                                    type="button"
                                                    onClick={(e) => guardarPrecios()}
                                                >Guardar Precios</button>
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default