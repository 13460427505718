export const textos = {
    login: {
        acceso_correcto: 'Te damos la bienvenida ',
        usuario_incorrecto: 'Usuario o contraseña incorrectos, favor de verificar sus datos.',
        usuario_incorrecto: 'Usuario o contraseña incorrectos, favor de verificar sus datos.',
        error_servidor: 'Ocurrió un error con la página, favor de recargar la página o vuelva más tarde. Si el problema persiste favor de comunicarse con soporte técnico.',
        datos_vacios: 'No se permiten campos vacíos, favor de ingresar el usuario y contraseña.',
        iniciado_sesion: 'Usted ya ha iniciado sesion antes.',
        existe_sesion: 'No es posible conectarse, ya existe una sesión activa en otro lugar, debera cerrar su sesión o espera a que expire.',
        envio_codigo: `Se envío el código de verificación a su número de celular, si no le llego el código vuelva a generarlo.`,
        no_pudo_envio: `No se pudo enviar el código de verificación a su número, vuelva a ingresar su usuario y contraseña para verificar.`,
        correo_incorrecto: 'El usuario es su correo electronico, ejemplo: usuario@empresa.com.',
        no_pudo_codigo: 'No se pudo enviar el código.',
        codigo_enviado_correo: 'Se envío el código de verificación a su correo electronico',
        codigo_enviado_numero: 'Se envío el código de verificación a su número de celular',
        codigo_min: 'El (Código) debe de contener 6 digitos.',
        codigo_vacio: `El (Código) no puede estar vacio.`
    },
    agregar_empresa: {
        error_insertar_lista: 'Debe seleccionar un elemento de la lista'
    },
    errors: {
        error_servidor: 'Ocurrió un error en el servidor, favor de comunicarse con soporte técnico.',
        error_agregar: 'Ocurrió un error al intentar agregar los datos.'
    },
    success: {
        insert_exito: 'Los datos se guardaron correctamente.',
        cambio_estatus_producto: 'Se cambio el estatus de esté producto.',
    },
    warning: {
        campos_vacios_sin_seleccionar: 'No se puede dar de alta existen campos vacíos o por seleccionar',
        producto_existente: 'Esté producto ya existe en base de datos, no es posible darlo de alta nuevamente.'
    },
    info: {
        title_eliminar: '¿Desea eliminar esté elemento?',
        text_eliminar: (text) => `${text} dejara de existir`,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
    },
    todos: (texto = '') => {
        return {
            inserccion_exitosa: `${texto} se insertó correctamente.`,
            _inserccion_exitosa: 'El registro se realizó con éxito.',
            datos_vacios: 'Los campos con (*) no pueden estar vacíos'
        }
    }
}