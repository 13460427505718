import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'

const Breadcrumb = props => {
    const Icon = props.icon
    const parent = props.parent
    let ruta = ''
    return (
        <Fragment>
            <div className="container-fluid d-print-none">
                <div className="card card-title-head">
                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <div className="page-header-left">
                                    <h3>{props.title}</h3>
                                    <ol className="breadcrumb pull-right">
                                        <li className="breadcrumb-item">
                                            <Link to={`${props.parent[0].link}`}>
                                                <Icon />
                                            </Link>
                                        </li>
                                        {parent.map((value, i) => {
                                            // ruta += `/${value}`
                                            if (i === props.parent.length - 1) {
                                                return (<li key={i} className="breadcrumb-item">
                                                    <Link to={`${value.link}`}>
                                                        {value.titulo}
                                                    </Link>
                                                </li>)
                                            } else {
                                                return (<li key={i} className="breadcrumb-item ">
                                                    <Link to={`${value.link}`} >
                                                        {value.titulo}
                                                    </Link>
                                                </li>)
                                            }
                                        })}
                                        {/* <li className="breadcrumb-item">
                                        <Link to={`/${props.parent}`}>
                                            {props.parent}
                                        </Link></li>
                                    {props.child? <li className="breadcrumb-item active">
                                            {props.child}
                                    </li>: ''} */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Breadcrumb
