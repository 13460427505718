import { useEffect, useState } from 'react';
const icons = require('react-feather')

const ListMenu = () => {
    return new Promise((resolve, reject) => {
        fetch('/rt_menu', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(response => {
                const menu = response.menu.map(v => {
                    return {
                        title: v.NOMBRE_PER,
                        icon: icons[v.ICON_PER],
                        type: 'sub',
                        badgeType: 'primary',
                        path: v.URL_PER,
                        active: false,
                        children: JSON.parse(v.CHILDREN).map(e => {
                            return { path: e.URL_PER, title: e.NOMBRE_PER, type: 'link' }
                        })
                    }
                })
                resolve(menu)
            })
    })
}

export const MENUITEMS = await ListMenu()

// const _MENUITEMS = [
//     {
//         title: 'Empresa', icon: Briefcase, type: 'sub', badgeType: 'primary', path: '/clientes', active: true, children: [
//             { path: '/empresa', title: 'Empresas', type: 'link' },
//             { path: '/empresa/editar-empresa', title: 'Editar empresa', type: 'link' },
//             { path: '/empresa/datos-fiscales', title: 'Datos fiscales', type: 'link' },
//         ]
//     },
//     {
//         title: 'Proveedores', icon: Truck, type: 'sub', badgeType: 'primary', path: '/proveedores', active: false, children: [
//             { path: '/proveedores', title: 'Proveedores', type: 'link' },
//             { path: '/proveedores/nuevo', title: 'Nuevo proveedores', type: 'link' },
//         ]
//     },
//     {
//         title: 'Almacenes', icon: Archive, type: 'sub', badgeType: 'primary', path: '/almacenes', active: false, children: [
//             { path: '/almacenes', title: 'Almacenes', type: 'link' },
//             { path: '/almacenes/nuevo', title: 'Nuevo almacen', type: 'link' },
//         ]
//     },
//     {
//         title: 'Familias', icon: AlignJustify, type: 'sub', badgeType: 'primary', path: '/familias-insumos', active: false, children: [
//             { path: '/familias-insumos', title: 'Familias', type: 'link' },
//             { path: '/familias-insumos/nuevo', title: 'Nueva familia', type: 'link' },
//         ]
//     },
//     {
//         title: 'Insumos', icon: Box, type: 'sub', badgeType: 'primary', path: '/insumos', active: false, children: [
//             { path: '/insumos', title: 'Insumos', type: 'link' },
//             { path: '/insumos/nuevo', title: 'Nuevos insumos', type: 'link' },
//         ]
//     },
//     {
//         title: 'Articulos', icon: Inbox, type: 'sub', badgeType: 'primary', path: '/articulos', active: false, children: [
//             { path: '/articulos', title: 'Articulos', type: 'link' },
//             { path: '/articulos/nuevo', title: 'Nuevos articulos', type: 'link' },
//         ]
//     },
//     {
//         title: 'Tipo inventario', icon: Inbox, type: 'sub', badgeType: 'primary', path: '/tipo-inventario', active: false, children: [
//             { path: '/tipo-inventario', title: 'Tipo inventario', type: 'link' },
//             { path: '/tipo-inventario/nuevo', title: 'Nuevo tipo inventario', type: 'link' },
//         ]
//     },
//     {
//         title: 'Maximos y minimos', icon: Inbox, type: 'sub', badgeType: 'primary', path: '/tipo-inventario', active: false, children: [
//             { path: '/maximos-minimos', title: 'Maximos y minimos', type: 'link' },
//             { path: '/maximos-minimos/nuevo', title: 'Nuevos maximos y minimos', type: 'link' },
//         ]
//     },
//     {
//         title: 'Requisiciones surtido', icon: Inbox, type: 'sub', badgeType: 'primary', path: '/tipo-inventario', active: false, children: [
//             { path: '/requisiciones-surtido', title: 'Requisiciones', type: 'link' },
//             { path: '/requisiciones-surtido/nuevo', title: 'Nueva requisición', type: 'link' },
//         ]
//     },
//     {
//         title: 'Recetas', icon: Inbox, type: 'sub', badgeType: 'primary', path: '/recetas', active: false, children: [
//             { path: '/recetas', title: 'Recetas', type: 'link' },
//             { path: '/recetas/nuevo', title: 'Nueva receta', type: 'link' },
//         ]
//     }
//     // {
//     //     title: 'Clientes', icon: CreditCard, type: 'sub', badgeType: 'primary', path: '/clientes', active: false, children: [
//     //         { path: '/clientes', title: 'Clientes', type: 'link' },
//     //         { path: '/clientes/nuevo', title: 'Nuevo Cliente', type: 'link' },
//     //     ]
//     // },
//     // {
//     //     title: 'Usuarios', icon: UserPlus, type: 'sub', badgeType: 'primary', path: '/clientes', active: false, children: [
//     //         { path: '/usuarios-cms', title: 'Usuario', type: 'link' },
//     //         { path: '/usuarios-cms/nuevo', title: 'Nuevo Usuario', type: 'link' },
//     //     ]
//     // },
//     // {
//     //     title: 'Clientes', icon: CreditCard, type: 'sub', badgeType: 'primary', path: '/clientes', active: false, children: [
//     //         { path: '/clientes', title: 'Clientes', type: 'link' },
//     //         { path: '/clientes/nuevo', title: 'Nuevo Cliente', type: 'link' },
//     //     ]
//     // }
// ]
