import React, { useState, useEffect, Fragment } from 'react';
import { Chart } from "react-google-charts";
import MySelect from '../common/select'
import Tools from '../../js/tools'
// import DatePicker from "react-datepicker";
import CountUp from 'react-countup'
// import DateTimePicker from 'react-datetime-picker';

const Default = (props) => {
    const optionsTipoGrafica = [{label: 'Dias', value: 0}, {label: 'Meses', value: 1}, {label: 'Años', value: 2}]
    const optionsTipo = [{label: 'Ventas en pesos por producto', value: 0}, {label: 'Ventas en numero por producto', value: 1}]
    const [chartData, setChartData] = useState([])
    const [chartColor, setChartColor] = useState([])
    const [nextGrafica, setNextGrafica] = useState(0)
    const [tipoGrafica, setTipoGrafica] = useState(0)
    const [tipo, setTipo] = useState(0)
    const [productosTotales, setProductosTotales] = useState({})
    const [ventasTotales, setVentasTotales] = useState(0)
    const [startDate, setStartDate] = useState(new Date())
    const [loading, setLoading] = useState(false)

    const onClickNextGrafica = (data) => {
        let body = props.body? props.body: {}
        body['NEXT'] = data
        body['TIPO_GRAFICA'] = tipoGrafica
        body['TIPO'] = tipo
        body['FECHA_INICIAL'] = startDate
        setLoading(true)

        Tools.toolFetch({ 
            ruta: props.ruta? props.ruta: '',
            method: 'POST',
            body: body,
        }, (response) => {
            if(response.estado){
                let arr = getPartArr2(response.resultado)
                let g = Tools.getGrafica(arr, 'Fecha')
                setChartColor(g.color)
                setChartData(g.mat)
                setNextGrafica(data)
                setProductos(arr)
                setLoading(false)
            }
        })
    }

    const  onChangeTipoGrafica = (v) => {
        let body = props.body? props.body: {}
        body['NEXT'] = 0
        body['TIPO_GRAFICA'] = v.value
        body['TIPO'] = tipo
        body['FECHA_INICIAL'] = startDate

        Tools.toolFetch({ 
            ruta: props.ruta? props.ruta: '',
            method: 'POST',
            body: props.body? props.body: {},
        }, (response) => {
            if(response.estado){
                let arr = getPartArr2(response.resultado)
                let g = Tools.getGrafica(arr, 'Fecha')
                setChartColor(g.color)
                setChartData(g.mat)
                setTipoGrafica(v.value)
                setProductos(arr)
            }
        })
    }

    const  onChangeTipo = (v) => {
        let body = props.body? props.body: {}
        body['NEXT'] = nextGrafica
        body['TIPO_GRAFICA'] = tipoGrafica
        body['TIPO'] = v.value
        body['FECHA_INICIAL'] = startDate

        Tools.toolFetch({ 
            ruta: props.ruta? props.ruta: '',
            method: 'POST',
            body: props.body? props.body: {},
        }, (response) => {
            if(response.estado){
                let arr = getPartArr2(response.resultado)
                let g = Tools.getGrafica(arr, 'Fecha')
                setChartColor(g.color)
                setChartData(g.mat)
                setTipo(v.value)
                setProductos(arr)
            }
        })
    }

    const onChangeDate = (date) => {
        let body = props.body? props.body: {}
        body['NEXT'] = 0
        body['TIPO_GRAFICA'] = tipoGrafica
        body['TIPO'] = tipo
        body['FECHA_INICIAL'] = date

        Tools.toolFetch({ 
            ruta: props.ruta? props.ruta: '',
            method: 'POST',
            body: props.body? props.body: {},
        }, (response) => {
            if(response.estado){
                let arr = getPartArr2(response.resultado)
                let g = Tools.getGrafica(arr, 'Fecha')
                setChartColor(g.color)
                setChartData(g.mat)
                setStartDate(date)
                setProductos(arr)
            }
        })
    }

    const getProductosTotal2 = (mat) => {
        let arr = mat[1],
            res = []
        
        arr.map((v, i) => {
            let sum = 0, p = '', id = 0
            v.map((t) => {
                id = t.ID_PRODUCTO
                p = t.DESCRIPCION_PRODUCTO
                sum += t.IMPORTE
            })
            res.push({ ID_PRODUCTO: id, DESCRIPCION_PRODUCTO: p, IMPORTE: sum })
        })

        return res
    }

    const getPartArr2 = (datos = []) => {
        let productos = datos[0],
            partidas = [],
            fechas = datos[2]

        if(datos[1].length > 0){
            datos[1].map((v, i) => {
                let p = fechas.findIndex((t) => t.ID_PRODUCTO === v.ID_PRODUCTO && t.FECHA === v.FECHA)
                if(p !== -1){
                    fechas[p].IMPORTE = v.IMPORTE
                }
            })
        }

        productos.map((v) => {
            partidas.push(fechas.filter((t) => (v.ID_PRODUCTO === t.ID_PRODUCTO)))
        })

        return [productos, partidas]
     }

    const setProductos = (arr) => {
        let productos = getProductosTotal2(arr)
        setProductosTotales(productos)

        if(productos.length > 0){
            let sum = 0
            productos.map((v) => {
                sum += v.IMPORTE
            })

            setVentasTotales(sum)
        }
    }

    useEffect(() => {
        let isSubscribed = true
        let body = props.body? props.body: {}
        body['NEXT'] = nextGrafica
        body['TIPO_GRAFICA'] = tipoGrafica
        body['TIPO'] = tipoGrafica
        body['FECHA_INICIAL'] = startDate

        Tools.toolFetch({ 
            ruta: props.ruta? props.ruta: '',
            method: 'POST',
            body: props.body? props.body: {},
        }, (response) => {
            if(isSubscribed){
                if(response.estado){
                    let arr = getPartArr2(response.resultado), 
                    g = Tools.getGrafica(arr, 'Fecha')
                    setChartColor(g.color)
                    setChartData(g.mat)
                    setProductos(arr)
                }
            }
        })
        return () => isSubscribed = false
    }, [props.body])

    return (
    <div className="row">
        <div className="col-xl-3 col-sm-3">
            <label className="col-form-label">Tipo Grafica</label>
            <MySelect
                className='form-control form-control-primary btn-square'
                id="list_tipo_grafica"
                name="list_tipo_grafica"
                defaultValue={optionsTipo[0]}
                onChange={onChangeTipo}
                options={optionsTipo} 
                />
        </div>
        <div className="col-xl-2 col-sm-2">
            <label className="col-form-label">Por Dias, Meses y Años</label>
            <MySelect
                className='form-control form-control-primary btn-square'
                id="list_dias"
                name="list_dias"
                defaultValue={optionsTipoGrafica[0]}
                onChange={onChangeTipoGrafica}
                options={optionsTipoGrafica} />
        </div>
        <div className="col-xl-2 col-sm-2">
            <label className="col-form-label">Fecha Final</label>
            {/* {tipoGrafica ===0? 
                // <DatePicker className="form-control digits text-right" 
                //     selected={startDate}
                //     dateFormat="yyyy/MM/dd"
                //     onChange={onChangeDate}
                //     onCalendarClose={() => null}
                //     onCalendarOpen={() => null}
                // />
                
                <DateTimePicker
                    className='form-control digits'
                    onChange={onChangeDate}
                    format='yyyy-MM-dd'
                    value={startDate}
                                />: ''} */}
            {/* {tipoGrafica ===1? 
            // <DatePicker className="form-control digits text-right" 
            //     selected={startDate}
            //     dateFormat="yyyy/MM"
            //     showMonthYearPicker={true}
            //     onChange={onChangeDate}
            //     onCalendarClose={() => null}
            //     onCalendarOpen={() => null}
            // />
            <DateTimePicker
            className='form-control digits'
            onChange={onChangeDate}
            format='yyyy-MM'
            value={startDate}
                        />
            : ''} */}
            {/* {tipoGrafica ===2? 
            // <DatePicker className="form-control digits text-right" 
            //     selected={startDate}
            //     dateFormat="yyyy"
            //     showYearPicker={true}
            //     onChange={onChangeDate}
            //     onCalendarClose={() => null}
            //     onCalendarOpen={() => null}
            // />
            <DateTimePicker
            className='form-control digits'
            onChange={onChangeDate}
            format='yyyy'
            value={startDate}
                        />: ''} */}
        </div>
        <div className="col-xl-5 col-sm-5 d-flex justify-content-left align-items-end">
        {loading ?<Fragment>
            <div className="loader-box justify-content-center d-flex" style={{height: 60}}>
                <span className="rotate dashed">
                </span>
            </div>
        </Fragment> : <Fragment>
            <button onClick={() => onClickNextGrafica(nextGrafica + 1)} className="btn btn-primary mb-1" type="button" style={{marginRight: 10}}><i className="fa fa-arrow-left"></i></button>
            <button onClick={() => onClickNextGrafica(nextGrafica - 1)} className="btn btn-primary mb-1" type="button" style={{marginRight: 10}}><i className="fa fa-arrow-right"></i></button>
            </Fragment>}
        </div>
        <div className="col-xl-12 col-sm-12 d-flex justify-content-left align-items-start">
            {chartData.length > 0?<Chart
                width={'100%'}
                height={400}
                chartType="LineChart" 
                loader={<div>Cargando...</div>}
                data={chartData}
                options={{
                    hAxis: {
                        title: 'Fecha',
                    },
                    vAxis: {
                        title: 'Ventas $',
                    },
                    colors: chartColor,
                    series: {
                        1: { curveType: 'function' },
                    },
                }}
                rootProps={{ 'data-testid': '2' }}
            />: <Chart
            width={'100%'}
            height={400}
            chartType="LineChart"
            loader={<div>Cargando...</div>}
            data={[
                ["-", "-", "-"],
                ["-", 0, 0],
                ["-", 0, 0],
                ["-", 0, 0],
                ["-", 0, 0],
                ["-", 0, 0],
                ["-", 0, 0],
                ["-", 0, 0],
                ["-", 0, 0],
                ["-", 0, 0],
                ["-", 0, 0],
            ]}
            options={{
                hAxis: {
                    title: '-',
                },
                vAxis: {
                    title: '-',
                },
                colors: ["#cc0a0a", "#5e8fc5", '#b6b6b6'],
                series: {
                    1: { curveType: 'function' },
                },
            }}
            rootProps={{ 'data-testid': '2' }}
        />}
        </div>
        {productosTotales.length > 0?<Fragment>
            <div className="col-xl-12 col-sm-12 d-flex justify-content-center align-items-start">
                <div className="row chart-bottom">
                    {productosTotales.map((v,i) => {
                        return (<div className="col text-center" key={i}>
                                <div className="d-inline-block">
                                    <h5 className="font-primary counter">
                                        <CountUp decimals={2} separator={','} end={v.IMPORTE} /></h5>
                                    <h6 className="mb-0">{v.DESCRIPCION_PRODUCTO}</h6>
                                </div>
                            </div>)
                            // <div>{v}: {Tools.toFixed2(productosTotales[v])}</div>
                    })}
                </div>
            </div>
            <div className="col-xl-12 col-sm-12 d-flex justify-content-center align-items-start">
                <div className="row chart-bottom">
                    <div className="col text-center">
                        <div className="d-inline-block">
                            <h5 className="font-primary counter">
                                <CountUp decimals={2} separator={','} end={ventasTotales} /></h5>
                            <h6 className="mb-0">Ventas Totales</h6>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        : ''}
    </div>)
}

// <div className="row chart-bottom">
// <div className="col text-center">
//     <div className="d-inline-block">
//         <h5 className="font-primary counter">
//             <CountUp end={75000} /></h5>
//         <h6 className="mb-0">Total Sale</h6>
//     </div>
// </div>
// <div className="col text-center">
//     <div className="d-inline-block">
//         <h5 className="font-primary counter">
//             <CountUp end={40000} /></h5>
//         <h6 className="mb-0">Bitcoin Sale</h6>
//     </div>
// </div>
// <div className="col text-center">
//     <div className="d-inline-block">
//         <h5 className="font-primary counter">
//             <CountUp end={35000} /></h5>
//         <h6 className="mb-0">Ethereum Sale</h6>
//     </div>
// </div>
// </div>
export default Default