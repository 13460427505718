import { Fragment, useState, useEffect } from 'react';
import { Home, Briefcase, BarChart2 } from 'react-feather';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useNavigate, useParams } from 'react-router-dom'
import { Alert } from 'reactstrap';
import RequisicionTabla from './requisiciones_surtido'
import Requisicion from './requisiciones_surtido_form'
import RequisicionArtIns from './requisiciones_surtido_artins'
// import Breadcrumb from '../../components/common/breadcrumb';
import Breadcrumb from '../common/breadcrumbv2';
import Toast from '../common/tool_toast'
import Tools from '../../js/tools'

const Default = () => {
    return (
        <Fragment>
            <Breadcrumb icon={Home} title='Requisiciones Buzón' parent={[{ titulo: 'requisiciones buzón', link: '/requisiciones-surtido' }]} />
            <div className="container-fluid">
                <div className="row theme-tab">
                </div >
            </div >
        </Fragment >)
}


export default Default;