import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Briefcase } from 'react-feather';

const Default = ({ rutaPlantillas, esHistorial }) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const estatus = [
        <span className="badge badge-warning">Pendiente</span>,
        <span className="badge badge-info">Autorizado</span>,
        <span className="badge badge-danger">En proceso</span>,
        <span className="badge badge-success">Completado</span>,
        <span className="badge badge-primary">Cancelado</span>
    ]

    useEffect(() => {
        setEsRefresh(!esRefresh)
    }, [rutaPlantillas])

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_requisiciones_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    <div className="card-header d-flex justify-content-center p-3">
                        <h4>Requisiciones Surtido</h4>
                    </div>
                    <div className="mt-3 px-4">
                        {rutaPlantillas ?
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                htmlBtnPag={
                                    <div className="btn-container">
                                        {(GetPermisos.find(permiso => [29].includes(permiso)) ? true : false) ?
                                            <button className='btn-nuevo' onClick={() => {
                                                navigate('/requisiciones-surtido/nueva-plantilla')
                                            }}>
                                                <span className="text">Nuevo</span>
                                                <div className="icon-container">
                                                    <div className="icon icon--left">
                                                    </div>
                                                    <div className="icon icon--right">
                                                    </div>
                                                </div>
                                            </button> : ''
                                        }
                                    </div>}
                                headers={[
                                    {
                                        nombre: 'CLAVE', tipo: 'html', texto: 'ID_RQI', html: (d) => {
                                            return <div>
                                                <strong>{d.ID_RQI}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'TITULO', tipo: 'html', texto: 'TITULO_RQI', html: (d) => {
                                            return <div>
                                                <strong>{d.TITULO_RQI}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'SOLICITANTE', tipo: 'html', texto: 'NOMBRES_UC', html: (d) => {
                                            return <div>
                                                <strong>{d.NOMBRES_UC} {d.APELLIDOS_UC}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'CORREO SOLICITANTE', tipo: 'html', texto: 'CORREO_UC', html: (d) => {
                                            return <div>
                                                <strong>{d.CORREO_UC}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'FECHA REQUISICION', tipo: 'html', texto: 'FECHA_FORMAT', html: (d) => {
                                            return <div>
                                                <strong>{d.FECHA_FORMAT}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                        html: (d) => {
                                            return <div>
                                                <BtnVer
                                                    onClick={() => {
                                                        navigate(`/requisiciones-surtido/${rutaPlantillas ? 'plantillas/' : ''}${d.ID_RQI}`)
                                                    }}
                                                />
                                                {(GetPermisos.find(permiso => [2064].includes(permiso)) ? true : false) ?
                                                    <BtnEliminar
                                                        onClick={() => {
                                                            notie.confirm({
                                                                text: `Se eliminara <strong>${d.NOMBRE_RQI}</strong> ¿estas de acuerdo?`,
                                                                submitText: 'Si, eliminar',
                                                                cancelText: 'No, Cancelar',
                                                                cancelCallback: function (value) {
                                                                },
                                                                submitCallback: function (value) {
                                                                    eliminar({ id: d.ID_RQI })
                                                                },
                                                            })
                                                        }}
                                                    /> : ''
                                                }
                                            </div>
                                        }
                                    }
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'/rt_requisiciones_list'}
                                refresh={esRefresh}
                                body={{ rutaPlantillas: rutaPlantillas }}
                                rowsPerPage={20}
                                paginadorTop={true}
                            >
                            </Table> :
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                htmlBtnPag={
                                    <div className="btn-container">
                                        {(!esHistorial && GetPermisos.find(permiso => [29].includes(permiso)) ? true : false) ?
                                            <button className='btn-nuevo' onClick={() => {
                                                navigate('/requisiciones-surtido/nuevo')
                                            }}>
                                                <span className="text">Nuevo</span>
                                                <div className="icon-container">
                                                    <div className="icon icon--left">
                                                    </div>
                                                    <div className="icon icon--right">
                                                    </div>
                                                </div>
                                            </button> : ''
                                        }
                                    </div>}
                                headers={[
                                    {
                                        nombre: 'CLAVE', tipo: 'html', texto: 'ID_RQI', html: (d) => {
                                            return <div>
                                                <strong>{d.ID_RQI}</strong><br />
                                            </div>
                                        }
                                    },
                                    rutaPlantillas ?
                                        {
                                            nombre: 'TITULO', tipo: 'html', texto: 'TITULO_RQI', html: (d) => {
                                                return <div>
                                                    <strong>{d.TITULO_RQI}</strong><br />
                                                </div>
                                            }
                                        }
                                        : {},
                                    {
                                        nombre: 'SOLICITANTE', tipo: 'html', texto: 'NOMBRES_UC', html: (d) => {
                                            return <div>
                                                <strong>{d.CORREO_UC}</strong><br />
                                            </div>
                                        }
                                    },
                                    // {
                                    //     nombre: 'CORREO SOLICITANTE', tipo: 'html', texto: 'CORREO_UC', html: (d) => {
                                    //         return <div>
                                    //             <strong>{d.CORREO_UC}</strong><br />
                                    //         </div>
                                    //     }
                                    // },
                                    {
                                        nombre: 'ALMACEN SOLICITANTE', tipo: 'html', texto: 'ALMACEN_SOLICITANTE', html: (d) => {
                                            return <div>
                                                <strong>{d.ALMACEN_SOLICITANTE}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'ALMACEN SURTIDOR', tipo: 'html', texto: 'ALMACEN_SURTIDOR', html: (d) => {
                                            return <div>
                                                <strong>{d.ALMACEN_SURTIDOR}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'TIPO', tipo: 'html', texto: 'CREADO_PLANTILLA_RQI', html: (d) => {
                                            return <div>
                                                <strong>{d.CREADO_PLANTILLA_RQI ? 'Plantilla' : 'Simple'}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'FECHA REQUISICION', tipo: 'html', texto: 'FECHA_FORMAT', html: (d) => {
                                            return <div>
                                                <strong>{d.FECHA_FORMAT}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'ESTATUS', tipo: 'html', texto: 'AUTORIZADO_RQI', html: (d) => {
                                            return <div>
                                                {estatus[d.ESTATUS_RQI - 1]}
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                        html: (d) => {
                                            return <div>
                                                <BtnVer
                                                    onClick={() => {
                                                        navigate(`/requisiciones-surtido/${rutaPlantillas ? 'plantillas/' : ''}${d.ID_RQI}`)
                                                    }}
                                                />
                                                {rutaPlantillas && (GetPermisos.find(permiso => [2064].includes(permiso)) ? true : false) ?
                                                    <BtnEliminar
                                                        onClick={() => {
                                                            notie.confirm({
                                                                text: `Se eliminara <strong>${d.NOMBRE_RQI}</strong> ¿estas de acuerdo?`,
                                                                submitText: 'Si, eliminar',
                                                                cancelText: 'No, Cancelar',
                                                                cancelCallback: function (value) {
                                                                },
                                                                submitCallback: function (value) {
                                                                    eliminar({ id: d.ID_RQI })
                                                                },
                                                            })
                                                        }}
                                                    /> : ''
                                                }
                                            </div>
                                        }
                                    }
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                path={'/rt_requisiciones_list'}
                                refresh={esRefresh}
                                body={{ rutaPlantillas: rutaPlantillas, id: params.id, esHistorial: esHistorial }}
                                rowsPerPage={20}
                                paginadorTop={true}
                            >
                            </Table>
                        }
                    </div>
                </div>
            </div>
        </Fragment >
    );
};

export default Default;